import React, { useState, useEffect } from "react";
import * as _ from "lodash-es";
// import propTypes from "prop-types";
import axios from "axios";
import styled from "styled-components";
import { Spin } from "antd";
import { GOALSWELL_API } from "utils/constants";
import Header from "./Header";
import { Header as GlobalHeader, Footer } from "components";
import FeaturedSection from "./FeaturedSection";
import BlogList from "./BlogList";
import ArrowButton from "components/ArrowButton2";
import { useParams } from "react-router-dom";

function Category() {
  const { category } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [categoryBlogs, setCategoryBlogs] = useState(null);
  useEffect(() => {
    setIsLoading(true);
    const url = `${GOALSWELL_API}/blogs?${
      category === "latest" ? "latest=1" : "category=" + category
    }`;
    axios
      .get(url)
      .then((res) => {
        setIsLoading(false);
        const data = res.data;
        setCategoryBlogs(data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, [category]);

  return (
    <Spin tip="Loading" spinning={isLoading}>
      <StyledContainer>
        <GlobalHeader showNav={true} />
        <Header />
        {categoryBlogs?.featured && (
          <FeaturedSection featuredBlog={categoryBlogs?.featured || {}} />
        )}
        {categoryBlogs?.featured && <div className="divived-line"></div>}
        <BlogList
          cards={_.map(categoryBlogs?.blogs || [], function (blog) {
            return {
              title: blog.title,
              description: blog.description,
              link: {
                text: "Read More",
                route: "/blogs" + blog.path,
              },
              media: {
                url: blog.coverImageUrl,
                alt: blog.coverImageAlt,
              },
              categories: blog.categories,
            };
          })}
        />
        <div className="see-all-wrapper">
          <ArrowButton
            text={"See All"}
            route={"/blogs/all"}
            fontColor="#6D64AD"
          />
        </div>
        <Footer />
      </StyledContainer>
    </Spin>
  );
}

const StyledContainer = styled.div.attrs({
  className: "",
})`
  .divived-line {
    border-top: 2px solid #e1e3e6;
  }

  .see-all-wrapper {
    margin-top: 48px;
    text-align: center;
  }
`;

Category.propTypes = {
  //   title: propTypes.string.isRequired,
};

export default Category;
