import React, { useState } from "react";
import { Modal } from "antd";
import { ReactComponent as CloseIcon } from "../../assets/icons/ico_close.svg";
import "./LegalModal.scss";
import EULA from "./EULA";
import TOU from "./TOU";
import PP from "./PP";
import IP from "./IP";

const CloseIconWrapper = function () {
  return (
    <span className="anticon anticon-close ant-modal-close-icon">
      <CloseIcon />
    </span>
  );
};

const SideBarLink = function ({ text, onClick, current }) {
  const className = `side-bar-link ${current ? 'current' : ''}`;
  return (
    <a className={className} href="/#" target="_self" onClick={onClick}>
      {text}
    </a>
  );
};

const LegalModal = ({
  visible = false,
  legal,
  onCancel = () => {}
}) => {
  const clearCurrent = {
    eula: false,
    tou: false,
    pp: false,
    ip: false
  };

  const initialState = legal => {
    legal = (legal || '').trim().toLowerCase();
    legal = ['tou', 'terms-of-use'].indexOf(legal) >= 0 ? 'tou' :
      ['privacy', 'privacy-policy'].indexOf(legal) >= 0 ? 'pp' :
      ['infringement', 'infringement-policy'].indexOf(legal) >= 0 ? 'ip' :
      'eula';
    return {
      eula: legal === 'eula',
      tou: legal === 'tou',
      pp: legal === 'pp',
      ip: legal === 'ip'
    };
  };

  const [current, setCurrent] = useState(initialState(legal));

  const showPolicy = policy => e => {
    e.preventDefault();
    setCurrent(clearCurrent);
    let newCurrent = clearCurrent;
    newCurrent[policy] = true;
    setCurrent(newCurrent);
  };

  return (
    <Modal
      className="legal-modal"
      title=""
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closeIcon={<CloseIconWrapper />}
      width="100%"
    >
      {/* <div className="legal-container">

        <div className="legal-header">
          All the legal jazz 👐
        </div> */}

        <div className="legal-body">
          <div className="side-bar-container">
            <div className="side-bar-title">
              Goalswell Terms
            </div>
            <SideBarLink
              text="End User License Agreement"
              onClick={showPolicy("eula")}
              current={current.eula}
            />
            <SideBarLink
              text="Terms of Use"
              onClick={showPolicy("tou")}
              current={current.tou}
            />
            <SideBarLink
              text="Privacy Policy"
              onClick={showPolicy("pp")}
              current={current.pp}
            />
            <SideBarLink
              text="Infringement Policy"
              onClick={showPolicy("ip")}
              current={current.ip}
            />
          </div>

          <div className="terms-container">
            <EULA visible={current.eula} showPolicy={showPolicy} />
            <TOU visible={current.tou} showPolicy={showPolicy} />
            <PP visible={current.pp} showPolicy={showPolicy} />
            <IP visible={current.ip} showPolicy={showPolicy} />
          </div>
        </div>

      {/* </div> */}
    </Modal>
  )
}

export default LegalModal;
