export const EMAIL_REGISTER_REQUEST = "LANDING/EMAIL_REGISTER_REQUEST";
export const EMAIL_REGISTER_REQUEST_WATCHER =
  "LANDING/EMAIL_REGISTER_REQUEST_WATCHER";

export const resetEmailRegisterAction = () => {    
  return {
    type: EMAIL_REGISTER_REQUEST,
    payload: false,
  };
};

export const emailRegisterAction = (emailAddress) => {
  return {
    type: EMAIL_REGISTER_REQUEST_WATCHER,
    emailAddress: emailAddress,
  };
};
