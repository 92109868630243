import React, { useState, useEffect } from "react";
import * as _ from "lodash-es";
import axios from "axios";
import styled, { createGlobalStyle } from "styled-components";
import { useParams, Link, useHistory } from "react-router-dom";
import cn from "classnames";
import { Select } from "antd";
import { GOALSWELL_API } from "utils/constants";
import { ReactComponent as IconLinkedIn } from "assets/icons/ico-linkedin.svg";
import { ReactComponent as IconTwitter } from "assets/icons/ico-twitter.svg";
import { ReactComponent as IconFacebook } from "assets/icons/ico-facebook.svg";
import { ReactComponent as IconInstagram } from "assets/icons/ico-instagram.svg";
import { ReactComponent as IconYoutube } from "assets/icons/ico-youtube.svg";
import { ReactComponent as IconSearch } from "assets/icons/icon-search.svg";
import { ReactComponent as ArrowIcon } from "assets/icons/nav-arrow.svg";
import SearchModal from "./SearchModal";
const { Option } = Select;

const iconsMap = {
  twitter: IconTwitter,
  facebook: IconFacebook,
  instagram: IconInstagram,
  linkedin: IconLinkedIn,
  youtube: IconYoutube,
};

function Header() {
  const history = useHistory();
  const { category } = useParams();
  const [navData, setNavData] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    const url = `${GOALSWELL_API}/blogs/sub-nav`;
    axios
      .get(url)
      .then((res) => {
        const data = res.data;
        setNavData(data);
      })
      .catch((err) => {
        // console.error(err);
      });
  }, []);

  return (
    <StyledContainer>
      <GlobalStyleForModal />
      <div className="center-block">
        <div className="left-part">
          <div className="header-label">News</div>
          {_.map(navData.navigation, function (nav, index) {
            let paresdCategory = "latest";
            if (nav.param.startsWith("category=")) {
              paresdCategory = nav.param.split("=")[1];
            }

            return (
              <div
                className={cn([
                  "header-label",
                  "text-category",
                  {
                    current: category === paresdCategory,
                  },
                ])}
                key={index}
              >
                <Link to={"/blogs/category/" + paresdCategory}>
                  {nav.label}
                </Link>
              </div>
            );
          })}

          <Select
            className="category-select"
            dropdownClassName="category-select-dropdown"
            defaultValue={category}
            onChange={(value) => {
              history.push("/blogs/category/" + value);
            }}
            suffixIcon={<ArrowIcon className="select-arrow" />}
            placeholder="Select Category"
          >
            {_.map(navData.navigation, function (nav, index) {
              let paresdCategory = "latest";
              if (nav.param.startsWith("category=")) {
                paresdCategory = nav.param.split("=")[1];
              }

              return (
                <Option value={paresdCategory} key={index}>
                  {nav.label}
                </Option>
              );
            })}
          </Select>
        </div>
        <div className="right-part">
          <div className="header-label">Follow us</div>
          <div className="social-wrapper">
            {_.map(navData.social, function (value, key) {
              const IconComponent = iconsMap[key.toLocaleLowerCase()];
              return (
                <a
                  className="social-icon-link"
                  href={value}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={key}
                >
                  <IconComponent className="social-icon" />
                </a>
              );
            })}
          </div>
          <IconSearch
            className="icon-search"
            onClick={() => {
              setIsModalVisible(true);
            }}
          />
        </div>
      </div>
      <SearchModal
        isModalVisible={!!isModalVisible}
        closeModal={() => {
          setIsModalVisible(false);
        }}
      />
    </StyledContainer>
  );
}

const GlobalStyleForModal = createGlobalStyle`
      .ant-select-dropdown.category-select-dropdown {
        border-radius: 0 0 12px 12px;
        padding: 0 0 16px;
        width: auto !important;
        z-index: 1200;

        .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
          background-color: #1020370a;
        }
      }
`;

const StyledContainer = styled.div.attrs({
  className: "blog-header page-section",
})`
  height: 80px;
  .center-block {
    display: flex;
    justify-content: space-between;
    .header-label {
      height: 80px;
      font-size: 14px;
      font-weight: bold;
      display: flex;
      text-align: center;
      align-items: center;
      color: #838c96;
      padding: 0 20px;
    }
    .header-label.text-category {
      padding: 0 18px;
      > a {
        color: #47525d;
      }
      &.current {
        > a {
          ${"" /* TODO: should use variable $blue-color in ../../assets/scss/_variables.scss, instead of hard coding */}
          color: #0062FF;
        }
      }
      &:hover {
        background: #1020370a;
        > a {
          ${"" /* TODO: should use variable $blue-color in ../../assets/scss/_variables.scss, instead of hard coding */}
          color: #0062FF;
        }
      }
    }

    .left-part {
      display: flex;
      align-items: center;

      @media screen and (max-width: 768px) {
        .text-category {
          display: none;
        }
      }

      .category-select {
        display: none;
        @media screen and (max-width: 768px) {
          display: inline-block;
          .ant-select-selector {
            height: unset;
          }
        }
        border-radius: 8px;
        .ant-select-selector {
          background-color: #ffffff14;
          border: none;
          border-radius: 8px;
          .ant-select-selection-item {
            font-weight: bold;
            font-size: 14px;
            color: #1b2224;
          }
        }
        .ant-select-arrow {
          color: #1b2224;

          .select-arrow {
            margin-top: -2px;
            transition: transform 0.2s cubic-bezier(0.46, 0, 0.3, 1);
            height: 14px;
            fill: none;
            stroke: #1b2224;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 2px;
          }
        }

        &.ant-select-open {
          .ant-select-arrow {
            .select-arrow {
              transform: rotate(180deg);
              transform-origin: 50% 50%;
            }
          }
        }
      }
    }

    .right-part {
      display: flex;
      align-items: center;
      .social-wrapper {
        display: flex;
        align-items: center;
      }

      @media screen and (max-width: 768px) {
        .header-label,
        .social-wrapper {
          display: none;
        }
      }

      .social-icon-link {
        margin-right: 20px;
        padding-top: 5px;
      }

      .social-icon {
        height: 20px;
        fill: #47525d;
      }

      .icon-search {
        height: 20px;
        width: 20px;
        position: relative;
        top: -2px;
        margin-left: 40px;
        cursor: pointer;
      }
    }
  }
`;

export default Header;
