import React, { useState, useEffect } from "react";
import * as _ from "lodash-es";
import axios from "axios";
import styled from "styled-components";
import { Spin } from "antd";
import { GOALSWELL_API } from "utils/constants";
import Header from "./Header";
import { Header as GlobalHeader, Footer } from "components";
import BlogList from "./BlogList";

const PAGE_SIZE = 12;

function BlogAll() {
  const [isLoading, setIsLoading] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [currantPage, setCurrantPage] = useState(1);
  const [isAllLoaded, setIsAllLoaded] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (isLoading || isAllLoaded) {
        return;
      }
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        // you're at the bottom of the page
        setCurrantPage(function (oldPage) {
          return oldPage + 1;
        });
      }
    }

    window.addEventListener("scroll", handleScroll);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isLoading, isAllLoaded]);

  useEffect(() => {
    setIsLoading(true);
    const url = `${GOALSWELL_API}/blogs?page=${currantPage}&size=${PAGE_SIZE}`;

    axios
      .get(url)
      .then((res) => {
        setIsLoading(false);
        const newBlogs = res.data.blogs;

        setBlogs(function (loadedBlogs) {
          return loadedBlogs.concat(newBlogs);
        });

        if (newBlogs.length < PAGE_SIZE) {
          setIsAllLoaded(true);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, [currantPage]);

  return (
    <Spin tip="Loading" spinning={isLoading}>
      <StyledContainer>
        <GlobalHeader showNav={true} />
        <Header />
        <div className="all-blog-title center-block">All Articles</div>
        <BlogList
          cards={_.map(blogs || [], function (blog) {
            return {
              title: blog.title,
              description: blog.description,
              link: {
                text: "Read More",
                route: "/blogs" + blog.path,
              },
              media: {
                url: blog.coverImageUrl,
                alt: blog.coverImageAlt,
              },
              categories: blog.categories,
            };
          })}
        />
        <Footer />
      </StyledContainer>
    </Spin>
  );
}

const StyledContainer = styled.div.attrs({
  className: "",
})`
  .all-blog-title {
    padding: 40px 24px;
    color: #1b2224;
    font-weight: bold;
    font-size: 28px;
  }
`;

export default BlogAll;
