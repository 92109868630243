import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import * as _ from "lodash-es";
import axios from "axios";
import { ReactComponent as CloseIcon } from "assets/icons/ico_close.svg";
import { Modal, Spin, Input } from "antd";
import { useHistory } from "react-router-dom";
import { ReactComponent as IconSearch } from "assets/icons/icon-search.svg";
import { GOALSWELL_API } from "utils/constants";

const CloseIconWrapper = function () {
  return (
    <span className="anticon anticon-close ant-modal-close-icon">
      <CloseIcon />
    </span>
  );
};

function parseBlogs(blogs) {
  const categoriedBlogs = {};
  _.each(blogs, function (blog) {
    _.each(blog.categories, function (category) {
      if (categoriedBlogs[category]) {
        categoriedBlogs[category].push(blog);
      } else {
        categoriedBlogs[category] = [blog];
      }
    });
  });
  return categoriedBlogs;
}

function SearchModal({ isModalVisible, closeModal }) {
  const history = useHistory();
  const [searchingText, setSearchingText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [matchedBlogs, setMatchedBlogs] = useState({});

  const onSearchInputChange = useCallback(
    _.debounce(function (value) {
      // onUpdateFilter({ name: value });
    }, 500),
    []
  );

  useEffect(() => {
    if (searchingText === "") {
      setMatchedBlogs({});
      return;
    }

    setIsLoading(true);
    const url = `${GOALSWELL_API}/blogs?search=${searchingText}`;

    axios
      .get(url)
      .then((res) => {
        setIsLoading(false);
        const blogs = res.data.blogs;
        setMatchedBlogs(parseBlogs(blogs));
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, [searchingText]);

  return (
    <Modal
      visible={isModalVisible}
      onCancel={closeModal}
      footer={null}
      className="full-screen-modal"
      closeIcon={<CloseIconWrapper />}
    >
      <Spin tip="Loading" spinning={isLoading}>
        <StyledContainer>
          <div className="search-wrapper">
            <Input
              autoComplete="off"
              placeholder="Search"
              value={searchingText}
              onChange={useCallback(
                function (e) {
                  setSearchingText(e.target.value);
                  onSearchInputChange(e.target.value);
                },
                [onSearchInputChange]
              )}
              prefix={<IconSearch />}
              allowClear
            />
          </div>
          <div className="list-container">
            {_.map(matchedBlogs, function (cateBlogs, cateName) {
              return (
                <div className="category-wrapper" key={cateName}>
                  <div className="category-title">{cateName}</div>
                  {_.map(cateBlogs, function (cateBlog, blogIndex) {
                    return (
                      <div
                        className="blog-wrapper"
                        onClick={() => {
                          history.push("/blogs" + cateBlog.path);
                          closeModal();
                        }}
                        key={blogIndex}
                      >
                        <img
                          className="blog-img"
                          src={cateBlog.coverImageUrl}
                          alt={cateBlog.coverImageAlt}
                        />
                        <div className="blog-title">{cateBlog.title}</div>
                        <div className="blog-description">
                          {cateBlog.description}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </StyledContainer>
      </Spin>
    </Modal>
  );
}

const StyledContainer = styled.div.attrs({
  className: "center-block",
})`
  padding: 60px 200px;

  @media screen and (max-width: 768px) {
    padding: 60px 20px;
  }

  .search-wrapper {
    margin-bottom: 48px;

    @media screen and (max-width: 768px) {
      padding: 0px 8px;
      margin-bottom: 32px;
    }

    .ant-input-affix-wrapper {
      border: 1px solid #00a0bc;
      border-radius: 6px;
      background-color: #fff;
      .ant-input-prefix {
        margin-right: 8px;
        > svg {
          height: 18px;
        }
      }
      .ant-input-suffix {
        .ant-input-clear-icon {
          font-size: 20px;
        }
      }
    }

    .ant-input {
      height: 40px;
    }
  }

  .list-container {
    .category-title {
      color: #838c96;
      font-weight: bold;
      font-size: 16px;
      line-height: 21px;
      margin-bottom: 36px;
    }

    .blog-wrapper {
      cursor: pointer;
      padding-left: 196px;
      padding-right: 16px;
      position: relative;
      margin-bottom: 32px;
      min-height: 110px;

      @media screen and (max-width: 768px) {
        padding-left: 0;
        padding-right: 0;
        min-height: unset;
      }
      .blog-img {
        position: absolute;
        left: 0;
        top: 0;
        width: 180px;
        height: 100%;
        border-radius: 8px;
        @media screen and (max-width: 768px) {
          position: unset;
          left: unset;
          top: unset;
          width: 100%;
          height: unset;
          margin-bottom: 16px;
          min-height: 110px;
        }
      }
      .blog-title {
        color: #1b2224;
        font-weight: bold;
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 16px;
      }
      .blog-description {
        font-size: 14px;
        line-height: 18px;
        color: #47525d;
      }
    }
  }
`;

export default SearchModal;
