import React from 'react';
import { SET_GOALS_IMAGE, LEARN_APPLY_IMAGE, LANDING_SECTION_BG1, LANDING_SECTION_BG2 } from "../../../assets";
import "./MiddleContent.scss";

const MiddleContent = () => {
  return (
    <div className="middle-container" id="features">
      <img className="section_bg1" src={LANDING_SECTION_BG1} alt="section_bg1" />
      <img className="section_bg2" src={LANDING_SECTION_BG2} alt="section_bg2" />
      <div className="middle-body">
        <div className="aim section">                
          <div className="aim-body">
            <div className="section-title">TAKE AIM!</div>
            <div className="section-subtitle">Target for <br/>success</div>
            <div className="section-text">Create and track goals to start improving your personal growth</div>       
          </div>
          <img className="set_goals_img" src={SET_GOALS_IMAGE} alt="set_goals" />
        </div>     
        <div className="learn_apply section">        
          <img className="learn_apply_img" src={LEARN_APPLY_IMAGE} alt="learn_apply" />
          <div className="learn_apply-body">
            <div className="section-title">LEARN AND APPLY!</div>
            <div className="section-subtitle">Discover brief videos on behaviors</div>
            <div className="section-text">Put these lessons to practice by setting personal goals</div>               
          </div>
        </div>
      </div>
    </div>
  )
}
export default MiddleContent;