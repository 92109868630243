import React from "react";
import "./Panel.scss";

const IP = function ({ visible, showPolicy }) {
  console.log(`panel IP visible: ${visible}`);
  return (
    <div className="panel" style={{display: visible ? "block" : "none"}}>
      <h1>Infringement Policy</h1>
      <h4>Effective: March 19, 2021</h4>
      <p>Goalswell is committed to respecting the rights of copyright and trademark owners. We respond to notices of copyright infringement in accord with the Digital Millennium Copyright Act ("DMCA") and we use a similar process for trademark infringement claims, as outlined below.</p>
      <h3>1. Copyright</h3>
      <p>To notify us of copyright infringement, please send us a written notice by mail or email that includes all of the following information:</p>
      <ol>
        <li>Identification of the copyrighted work you believe is being infringed.</li>
        <li>Identification of the work you believe is infringing, with information that is reasonably sufficient for us to find it.</li>
        <li>Your name, address, phone number and email address.</li>
        <li>A statement that you have a good faith belief that use of the work in the manner complained of is not authorized by the copyright owner, its agent, or the law. </li>
        <li>A statement that the information in your notification is accurate, and under penalty of perjury, you are the copyright owner or are authorized to act on behalf of the owner of an exclusive right that has allegedly been infringed.</li>
        <li>A physical or electronic signature of a person authorized to act on behalf of the owner of the exclusive right that has allegedly been infringed.</li>
      </ol><br/>
      <p>Goalswell will treat notices that meet these requirements in accord with the DMCA. If we have taken down any content that you believe should not have been removed, you can send us a written counter-notice by mail or email. Please be sure your counter-notice meets the DMCA requirements and we will follow the process set out in the DMCA. You may find out more about the DMCA at <a href="https://copyright.gov" target="_blank" rel="noopener noreferrer">https://copyright.gov</a>.</p>

      <h3>2. Trademark</h3>
      <p>To notify us of trademark infringement, please send us a written notice that includes: (a) the specific trademark you claim is being infringed, (b) detailed information about the trademark you claim is infringing your trademark and where to locate that infringing trademark on our site, (c) your name, address and email address, (d) statements that are the equivalent to #4 and #5 above but as to the trademark instead of a copyright, (e) a physical or electronic signature meeting the requirements of #6 above, and (f) the serial or registration number of your trademark if it has been registered.</p>

      <h3>3. Goalswell’s Response</h3>
      <p>We will respond to all infringement notices that meet with the requirements set forth above and comply with applicable law. We reserve the right to remove any content without prior notice and at our sole discretion. We may disable and/or terminate the accounts of users who repeatedly infringe or are repeatedly charged with infringing the copyrights or other intellectual property rights of others.</p>

      <h3>4. Where to Send Notices</h3>
      <p>Please send copyright and trademark notices to:</p><br/>

      <p>Goalswell Inc.<br/>
      370 N. Halstead St.<br/>
      Pasadena, CA  91107<br/>
      Attn: Legal Department</p><br/>

      <p>Email: <a href="mailto:legal@goalswell.com" target="_self" rel="noopener noreferrer">legal@goalswell.com</a></p>
    </div>
  );
};

export default IP;
