import React from "react";
import * as _ from "lodash-es";
import propTypes from "prop-types";
import styled from "styled-components";
import MediaCard from "components/MediaCard";

function BlogList({ cards, link }) {
  return (
    <StyledContainer>
      <div className="cards-container">
        {_.map(cards, function (item, index) {
          if (!item) {
            return;
          }
          return (
            <MediaCard
              key={index}
              title={item.title}
              description={item.description}
              media={item.media}
              link={item.link}
              categories={item.categories}
            />
          );
        })}
      </div>
      {/* <div className="see-all-section">
        <ArrowButton text={link.text} route={link.route} fontColor="#6D64AD" />
      </div> */}
    </StyledContainer>
  );
}

const StyledContainer = styled.div.attrs({
  className: "center-block",
})`
  .title {
    text-align: left;
    font-weight: bold;
    font-size: 28px;
    color: #47525d;

    @media screen and (max-width: 768px) {
      font-size: 22px;
      padding-top: 36px;
    }
  }

  .cards-container {
    padding-top: 26px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 48px;
    grid-column-gap: 48px;
    row-gap: 78px;
    grid-row-gap: 78px;
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
      column-gap: 24px;
      grid-column-gap: 24px;
      row-gap: 24px;
      grid-row-gap: 24px;
    }
    position: relative;
  }

  .see-all-section {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
  }
`;

BlogList.propTypes = {
  //   link: propTypes.shape({
  //     text: propTypes.string.isRequired,
  //     route: propTypes.string.isRequired,
  //   }),
  cards: propTypes.arrayOf(
    propTypes.shape({
      title: propTypes.string.isRequired,
      description: propTypes.string.isRequired,
      link: propTypes.shape({
        text: propTypes.string.isRequired,
        route: propTypes.string.isRequired,
      }),
      media: propTypes.shape({
        url: propTypes.string,
        alt: propTypes.string,
      }),
      categories: propTypes.array,
    })
  ),
};

export default BlogList;
