import React, { useEffect, useState } from "react";
import "./EmailView.scss";
import { validate } from "../../utils/validation";
import { connect } from 'react-redux';
import { emailRegisterAction, resetEmailRegisterAction } from "../../redux/actions";

const EmailView = ({emailRegisterAction, resetEmailRegisterAction, isEmailRegistered}) => {
  const [emailAddress, setEmailAddress] = useState("");

  useEffect(() => { 
    resetEmailRegisterAction();
  }, [resetEmailRegisterAction]);

  useEffect(() => { 
    if(isEmailRegistered) {
      setEmailAddress("");
    }
  }, [isEmailRegistered]);

  const sendEmail = () => {
    if (emailAddress === "" || validate("email", emailAddress)) return;

    emailRegisterAction(emailAddress);
  };

  return (
    <div className="email-container">
      <input
        placeholder="Email"
        className="email"
        onChange={(e) => setEmailAddress(e.target.value)}
        type="email"
        value={emailAddress}
      />
      <div className="btn-reserve-spot" onClick={sendEmail}>
        Keep me updated
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isEmailRegistered: state.landing.isEmailRegistered,
  };
};

export default connect(mapStateToProps, {emailRegisterAction, resetEmailRegisterAction}) (EmailView);
