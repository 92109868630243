import React from 'react';
import "./Landing.scss";
import { 
  Header, 
  Footer, 
  HeadContent, 
  MiddleContent, 
  SupportContent,
  GetStarted,
  AboutUs
} from "../../components";

export default function Landing() {
  return (
    <div className="landing-container">
      <Header showNav={true} />  
      <HeadContent/>   
      <MiddleContent/>
      <SupportContent/>
      <GetStarted/>
      <AboutUs/>
      <Footer/>
    </div>
  )
}