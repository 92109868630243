import * as types from './actions';

const initialState = {  
  isEmailRegistered: false
};

const landing = (state = initialState, action) => {
  console.log("action=", action);

  switch (action.type) {
    case types.EMAIL_REGISTER_REQUEST:
      return {
        ...state,        
        isEmailRegistered: action.payload
      };
    default:
      return state;
  }
};

export default landing;
