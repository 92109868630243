import React from "react";
import { LANDING_SECTION_BG3_CIRCLE, MONITOR_GOALS_IMAGE, SUPPORT_FRIENDS_IMAGE } from "../../../assets";
import "./SupportContent.scss";

const SupportContent = () => {
  return (
    <div className="support-container">      
      <img className="section_circle_img" src={LANDING_SECTION_BG3_CIRCLE} alt="section_circle" />
      <div className="support-body">
        <div className="friend section">
          <div className="friend-body">
            <div className="section-title">Support your Friends!</div>
            <div className="section-subtitle">Encourage friends to hit their mark</div>
            <div className="section-text">
              Earn badges and rewards for inviting and cheering on your friends for their successes
            </div>
          </div>
          <img className="support_friends_img" src={SUPPORT_FRIENDS_IMAGE} alt="support_friends" />
        </div>
        <div className="monitor section">
          <img className="monitor_goals_img" src={MONITOR_GOALS_IMAGE} alt="monitor_goals" />
          <div className="monitor-body">
            <div className="section-title">A little nudge</div>
            <div className="section-subtitle">Friends can help you reach your marks too</div>
            <div className="section-text">
            Research shows selecting someone to watch over your goals helps you complete them! We make that process easy.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SupportContent;
