export const HEADER_LOGO_ICON = require('./icons/ico_header_logo_no_tag.svg');
export const FOOTER_MOBLAB_ICON = require('./icons/ico_footer_moblab.svg');
// export const CLOSE_ICON = require('./icons/ico_close.svg');
// export const FACEBOOK_ICON = require('./icons/ico-facebook.svg');
// export const LINKEDIN_ICON = require('./icons/ico-linkedin.svg');
// export const TWITTER_ICON = require('./icons/ico-twitter.svg');

export const HERO_PHONE_IMAGE = require('./images/img_landing_hero_phone@2x.png');
export const HERO_APP_STORE_IMAGE = require('./images/img_landing_hero_app_store.svg');
export const HERO_GOOGLE_PLAY_IMAGE = require('./images/img_landing_hero_google_play.svg');
export const HERO_BASE_IMAGE = require('./images/img_landing_hero_base.png');

export const SET_GOALS_IMAGE = require('./images/img_landing_set_goals@2x.png');
export const LEARN_APPLY_IMAGE = require('./images/img_landing_learn_apply@2x.png');
export const LANDING_SECTION_BG1 = require('./images/img_landing_section_bg1.svg');
export const LANDING_SECTION_BG2 = require('./images/img_landing_section_bg2.svg');

export const SUPPORT_FRIENDS_IMAGE = require('./images/img_landing_support_friends@2x.png');
export const MONITOR_GOALS_IMAGE = require('./images/img_landing_monitor_goals@2x.png');
export const LANDING_SECTION_BG3 = require('./images/img_landing_section_bg3.svg');
export const LANDING_SECTION_BG3_CIRCLE = require('./images/img_landing_section_bg3_circle.svg');

export const FOOTER_SIGNUP_IMAGE = require('./images/img_landing_footer_signup@2x.png');

export const PORTRAIT = require('./images/portrait.jpg');