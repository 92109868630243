import React, { useCallback } from "react";
import { HERO_APP_STORE_IMAGE, HERO_BASE_IMAGE, HERO_GOOGLE_PLAY_IMAGE } from "../../../assets";
// import EmailView from "../../EmailView";
import "./HeadContent.scss";
import HeroPhoneAnimationData from "../../../assets/animation/landing_hero_phone.json";
import Lottie from "react-lottie";
import { connect } from "react-redux";
import { HashLink } from 'react-router-hash-link';
import { APP_STORE_URL, PLAY_STORE_URL } from '../../../utils/constants';
import { logStore } from '../../../services/analytics';

const HeadContent = ({ isEmailRegistered }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: HeroPhoneAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const openStoreLink = useCallback(os => () => {
    logStore(os);
  }, []);

  return (
    <div className="head-container">
      <div className="head-body">
        <img className="hero-base" src={HERO_BASE_IMAGE} alt="hero-base" />
        <div className="hero-phone">
          <Lottie options={defaultOptions} />
        </div>
        <div className="content-body">
          <div className="head-title">
            Make healthy habits through social goal setting
          </div>
          <div className="head-subtitle">
            Download for FREE!
            {/* {isEmailRegistered
              ? "Thanks! We’ll let you know as soon as Goalswell is released."
              : "Enter your email and we’ll let you know when Goalswell is live!"} */}
          </div>
          <div className="head-actions">
            <a
              href={APP_STORE_URL}
              target="_blank"
              rel="noopener noreferrer"
              onClick={openStoreLink('iOS')}
            >
              <img className="apple head-action" src={HERO_APP_STORE_IMAGE} alt="App Store" />
            </a>
            <a
              href={PLAY_STORE_URL}
              target="_blank"
              rel="noopener noreferrer"
              onClick={openStoreLink('Android')}
            >
              <img className="google head-action" src={HERO_GOOGLE_PLAY_IMAGE} alt="Google Play" />
            </a>
            <HashLink className="head-action" to="/#subscribe" smooth={true}>
              <div className="btn-reserve-spot">
                Sign up for updates!
              </div>
            </HashLink>
          </div>
          {/* <EmailView /> */}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isEmailRegistered: state.landing.isEmailRegistered,
  };
};

export default connect(mapStateToProps, {})(HeadContent);
