import React from "react";
import PolicyLink from "./PolicyLink";
import "./Panel.scss";

const EULA = function ({ visible, showPolicy }) {
  // console.log(`panel EULA visible: ${visible}`);
  return (
    <div className="panel" style={{display: visible ? "block" : "none"}}>
      <h1>End User License Agreement</h1>
      <h4>Effective: March 19, 2021</h4>
      <p>
        Whenever you use a Goalswell web service or download and/or install a Goalswell application, this Goalswell End User License Agreement ("License") applies to you.  This License also incorporates by reference the Goalswell <PolicyLink text="Terms of Use" policy="tou" showPolicy={showPolicy} /> (“Terms”) and governs your use of the Goalswell web service(s), application(s), and their related user documentation (the "App").<br />
        We know everyone hates reading legal documents but please read this one.  By downloading, installing and/or using an App, you are agreeing to this License.  If you don’t agree with any of these terms, you cannot download, install or use our App(s).
      </p>
      <h3>1. Changes to this License</h3>
      <p>
        We reserve the right to change all or part of the License at any time.  If we do that, we will post the changed terms to the same web page as the old terms. IF YOU CONTINUE TO USE AN APP AFTER WE POST CHANGED TERMS, THAT USE WILL CONSTITUTE YOUR ACCEPTANCE OF THE CHANGED TERMS.
      </p>
      <h3>2. Changes to the Apps, Services and/or Fees</h3>
      <p>
        We are constantly evolving our products. This means we may change or discontinue an App (and/or our website and any of our other services) without notice or liability to you.  We may decide to charge or change the fee or payment terms for an App and/or services that integrate with an App.
      </p>
      <h3>3. License Grant</h3>
      <p>
        Goalswell grants you a revocable, non-exclusive, non-transferable, non-sublicensable, limited license to download, install and use the App for your personal non-commercial purposes, strictly in accordance with the terms in this License, including without limitation all restrictions in this License. Any attempt to use the App other than as permitted by this License will immediately terminate the license.<br />
        Except for the rights explicitly granted in this License, Goalswell retains all right, title and interest (including all intellectual property rights) in the App, including the copy of the App on your device.<br />
        Goalswell may use third-party software that is subject to open source and/or third-party license terms.  You are subject to those terms.
      </p>
      <h3>4. License Restrictions</h3>
      <p>
        You may not:
      </p>
      <ul>
        <li>
          rent, lease, sublicense, sell, assign, loan, or otherwise transfer the App, your copy of the App or any of your rights and obligations under this License;
        </li>
        <li>
          remove or destroy any copyright notices or other proprietary markings on the App;
        </li>
        <li>
          reverse engineer, decompile, disassemble, modify or adapt the App, merge the App into another program, or create derivative works of the App;
        </li>
        <li>
          copy or distribute the App; or
        </li>
        <li>
          use the App in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with this License.
        </li>
      </ul>
      <p>
        You may print copies of any user documentation provided in online or electronic form for your personal use.
      </p>
      <h3>5. Termination</h3>
      <p>
        This License will terminate automatically upon the earlier of: (a) your failure to comply with any term of this License (whether or not we inform you of this termination); (b) you deleting the App from your device; and (c) if you are using the App in connection with a paid service, the end of the time period specified at time of purchase. In addition, Goalswell may terminate this License at any time, for any reason or no reason. If this License terminates, you must stop using the App and delete it from your device.
      </p>
      <h3>6. Compliance</h3>
      <p>
        You are solely responsible for compliance with any applicable laws and regulations and your own contractual obligations to third parties.
      </p>
      <h3>7. Privacy policy</h3>
      <p>
        If you download and/or use an App, you are subject to our <PolicyLink text="Privacy Policy" policy="pp" showPolicy={showPolicy} />.
      </p>
      <h3>8. Transmission of Data</h3>
      <p>
        Use of the App may involve the transmission of data over the Internet or cellular network to Goalswell and to and from third parties. For the sake of copy protection, a valid activation might be verified automatically by the App from time to time.
      </p>
      <h3>9. Trademarks</h3>
      <p>
        Certain of the product and service names used in this License and in the App may constitute trademarks of Goalswell or third parties. You are not authorized to use any such trademarks. All trademarks are the property of their respective owners.
      </p>
      <h3>10. Export Controls</h3>
      <p>
        You agree not to export, re-export or use the App except as explicitly authorized by United States law and the laws of the jurisdiction in which you obtained your App license. If you download an App in the United States, you may not export or re-export the App to any U.S. embargoed country or to anyone on the U.S. Treasury Department's list of Specially Designated Nationals or U.S. Department of Commerce's Denied Person's List or Entity List. By using the App, you represent and warrant that you are not located in any such country or on any such list. You also agree that you will not use the App for any purposes prohibited by United States law, including without limitation, the development, design, manufacture or production of nuclear missiles or chemical or biological weapons.
      </p>
      <h3>11. U.S. Government End Users</h3>
      <p>
        The App constitutes a “commercial item” as defined by 48 C.F.R. article 2.101 and consists of “commercial computer software” and “commercial computer software documentation” as such terms are used in 48 C.F.R. article 12.212 or 48 C.F.R. article 227.7202. The App is being licensed to U.S. Government end users only as commercial items, consistent with 48 C.F.R. article 12.212 or 48 C.F.R. article 227.7202-1 through 227.7202-4 as applicable, and with only the same rights granted to all other end users in the terms and conditions of this License. All unpublished rights are reserved under the copyright laws of the United States and international Licenses.
      </p>
      <h3>12. Miscellaneous</h3>
      <p>
        The <PolicyLink text="Terms of Use" policy="tou" showPolicy={showPolicy} />, this License and the <PolicyLink text="Privacy Policy" policy="pp" showPolicy={showPolicy} /> are the entire agreement between you and Goalswell relating to the App and they supersede all prior oral or written communications and representations with respect to the App or any other subject matter covered by this License.
      </p>
    </div>
  );
};

export default EULA;
