import React from 'react';
import "./DataDeletion.scss";
import { 
  DataDeletionStatus, 
  Footer, 
  Header, 
} from "../../components";

export default function DataDeletion() {
  return (
    <div className="data-deletion-container">
      <Header/>  
      <DataDeletionStatus/>
      <Footer/>
    </div>
  )
}