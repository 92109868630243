import { GOALSWELL_BASE_URL } from "../utils/constants";

export function logEvent(name, properties) {
  try {
    console.log(`sending event: ${name}, properties: ${JSON.stringify(properties, null, 2)}`);
    window.gtag('event', name, properties);
    // console.log(`sending event completed`);
  } catch (error) {
    // console.log(`sending event failed`);
    console.error(error);
  }
}

export function logPageView(title, path) {
  let properties = { page_title: title };
  if (path) {
    path = `${path.startsWith('/') ? '' : '/'}${path}`;
    properties = {
      ...properties,
      page_location: `${GOALSWELL_BASE_URL}${path}`,
      page_path: path,
    }
  }
  logEvent('page_view', properties);
}

export function logEmailRegistration() {
  logEvent('email_registration');
}

export function logStore(os) {
  // built-in event
  // logEvent('click', { type: 'store', os });
  // Custom event
  logEvent('store_clicked', { os });
}

export function logJobView() {
  logEvent('job_viewed');
}

export function logJobApplication() {
  logEvent('job_applied');
}
