import React from "react";
import "./App.css";
import GlobalAntdOverwriteStyle from "components/GlobalAntdOverwriteStyle";

import Routes from "./route";
import { store, persistor } from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

function App() {
  return (
    <Provider store={store}>
      <GlobalAntdOverwriteStyle />
      <PersistGate loading={null} persistor={persistor}>
        <Routes />
      </PersistGate>
    </Provider>
  );
}

export default App;
