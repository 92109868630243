import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./Footer.scss";
import { FOOTER_MOBLAB_ICON } from "../../assets";
import { ReactComponent as TwitterIcon } from "../../assets/icons/ico-twitter.svg";
import { ReactComponent as FacebookIcon } from "../../assets/icons/ico-facebook.svg";
import { ReactComponent as InstagramIcon } from "../../assets/icons/ico-instagram.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/icons/ico-linkedin.svg";
import LegalModal from "../LegalModal";
import { MOBLAB_BASE_URL, TWITTER_LINK, FACEBOOK_LINK, INSTAGRAM_LINK, LINKEDIN_LINK } from "../../utils/constants";

const IconLink = ({ route, text, icon }) => {
  return (
    <a className="icon-link" href={route} target="_blank" rel="noopener noreferrer">
      {icon}{text}
    </a> 
  );
};

const Footer = () => {
  // https://reacttraining.com/blog/react-router-v5-1/
  const { category, type } = useParams();
  console.log(`category: ${category}, type: ${type}`);
  const [isLegalVisible, setIsLegalVisible] = useState(false);

  useEffect(() => {
    if (category === 'legal') {
      setIsLegalVisible(true);
    }
  }, [category]);

  const showLegal = useCallback((e) => {
    e.preventDefault();
    setIsLegalVisible(true);
  }, []);

  const closeLegal = useCallback(() => {
    setIsLegalVisible(false);
  }, []);

  return (
    <div className="footer-container">
      <div className="footer-body">

        <div className="footer-links">
          <div className="links">
            <div className="header">Resources</div>
            <a className="link" href="/#" target="_self" onClick={showLegal}>
              Privacy & Terms
            </a>
          </div>

          <div className="links social">
            <div className="header">Follow us</div>
            <IconLink route={TWITTER_LINK} text="Twitter" icon={<TwitterIcon/>} />
            <IconLink route={FACEBOOK_LINK} text="Facebook" icon={<FacebookIcon/>} />
            <IconLink route={INSTAGRAM_LINK} text="Instagram" icon={<InstagramIcon/>} />
            <IconLink route={LINKEDIN_LINK} text="Linked-in" icon={<LinkedinIcon/>} />
          </div>
        </div>

        <div className="footer-bottom">
          <div className="powered-by-content">
            <div className="bottom-text">A</div>
            <a href={MOBLAB_BASE_URL} target="_blank" rel="noopener noreferrer">
              <img className="footer-moblab-logo" src={FOOTER_MOBLAB_ICON} alt="moblab-logo" />
            </a>
            <div className="bottom-text">company</div>
          </div>
          <div className="bottom-text">© 2021 Goalswell. All Rights Reserved</div>
        </div>

      </div>

      <LegalModal
        visible={isLegalVisible}
        legal={type}
        onCancel={closeLegal}
      />
    </div>
  );
};

export default Footer;
