import React from 'react';
import { HEADER_LOGO_ICON } from "../../assets";
import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from "react-helmet";
import "./Header.scss";
import * as routes from "../../route/routes";
import { ReactComponent as IconArrow } from "assets/icons/icon-arrow.svg";

const Header = ({ showNav=false }) => {
  return (
    <div className="header-container">
      <Helmet>
        <title>Goalswell: Goal & Habit Tracker</title>
        <meta name="description" content="Goalswell: Goal & Habit Tracker, Empowering you with financial knowledge to goal well with friends" />
      </Helmet>
      <div className="header-body">
        <div>
          <NavLink to={routes.LANDING}><img className="header-logo" src={HEADER_LOGO_ICON} alt="logo" /></NavLink>
        </div>
        { showNav && (
          <>
            <div className="header-nav-container">
              <HashLink to="/#features" smooth={true}><span className="header-nav">Features</span></HashLink>
              <HashLink to="/#about_us" smooth={true}><span className="header-nav">About Us</span></HashLink>
              <NavLink to="/blogs/category/latest"><span className="header-nav" style={{paddingRight: "5px"}}>Blog</span><IconArrow className="nav-icon-arrow"/></NavLink>
            </div>
            <div>
              <img className="header-logo header-logo-placeholder" src={HEADER_LOGO_ICON} alt="" />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
export default Header;