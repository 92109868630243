import React from "react";
// import * as _ from "lodash-es";
import propTypes from "prop-types";
import styled from "styled-components";

function generateTargetValueFromHref(url) {
  if (url && url.startsWith("/")) {
    // relative url
    return "_self";
  } else {
    return "_blank";
  }
}
function ArrowButton2({
  text,
  route,
  fontColor = "var(--ml-blue-1)",
  onClick = () => {},
  target = null,
}) {
  return (
    <StyledContainer fontColor={fontColor}>
      <a
        className="ctaButton variant--Link"
        href={route}
        target={target || generateTargetValueFromHref(route)}
        onClick={onClick}
      >
        {text}&#65279;
        <svg
          className="hoverArrow"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          aria-hidden="true"
        >
          <g fillRule="evenodd">
            <path className="hoverArrow__line" d="M11,8H5" />
            <path className="hoverArrow__tip" d="M7,4l4,4L7,12" />
          </g>
        </svg>
      </a>
    </StyledContainer>
  );
}

const StyledContainer = styled.div.attrs({
  className: "arrow-button",
})`
  .ctaButton {
    display: inline-block;
    padding: 3px 0 6px;
    border-radius: 14px;
    font: 700 14px Lato, sans-serif;
    color: ${({ fontColor }) => fontColor};
    white-space: nowrap;
    transition: var(--hoverTransition);
    outline: none;
    text-decoration: none;
  }

  .CtaButton.variant--Link {
    transition-property: color, opacity;
  }

  .hoverArrow {
    --arrowHoverTransition: 180ms cubic-bezier(0.46, 0, 0.3, 1);
    --arrowHoverOffset: translateX(3px);
    position: relative;
    top: 3px;
    margin-left: 3px;
    stroke-width: 2;
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
  }

  .hoverArrow__line {
    opacity: 0;
    transition: opacity var(--hoverTransition, var(--arrowHoverTransition));
  }

  .hoverArrow__tip {
    transition: transform var(--hoverTransition, var(--arrowHoverTransition));
  }

  @media (pointer: fine) {
    a:hover .hoverArrow__line,
    button:hover .hoverArrow__line {
      opacity: 1;
    }

    a:hover .hoverArrow__tip,
    button:hover .hoverArrow__tip {
      transform: var(--arrowHoverOffset);
    }
  }

  @media (pointer: coarse) {
    a:active .hoverArrow__line,
    button:active .hoverArrow__line {
      opacity: 1;
    }

    a:active .hoverArrow__tip,
    button:active .hoverArrow__tip {
      transform: var(--arrowHoverOffset);
    }
  }
`;

ArrowButton2.propTypes = {
  text: propTypes.string.isRequired,
};

export default ArrowButton2;
