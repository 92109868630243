import * as types from './actions';

const initialState = {  
  isDataDeleted: false,
  confirmationCode: undefined,
  error: undefined
};

const dataDeletion = (state = initialState, action) => {
  switch (action.type) {
    case types.DATA_DELETION_STATUS_REQUEST:
      return {
        ...state,        
        ...action.payload
      };
    default:
      return state;
  }
};

export default dataDeletion;
