import { put } from "redux-saga/effects";
import { EMAIL_REGISTER_REQUEST } from "./actions";
import Api from '../../services/api';
import { logEmailRegistration } from '../../services/analytics';
import { SEND_EMAIL_API } from '../../utils/constants';

export function* emailRegisterAction(action) {
  let data = {
    name: "NA",
    email: action.emailAddress,
  };

  try {
    const res = yield Api.post(SEND_EMAIL_API, data);
    console.log("res=", res);
    logEmailRegistration();
    yield put({ type: EMAIL_REGISTER_REQUEST, payload: true });
  } catch (error) {
    yield put({ type: EMAIL_REGISTER_REQUEST, payload: false });
  }
}