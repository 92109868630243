import React from "react";
import styled from "styled-components";
// import * as _ from "lodash-es";
import { ReactComponent as IconFacebook } from "assets/icons/ico-facebook.svg";
import { ReactComponent as IconLinkedIn } from "assets/icons/ico-linkedin.svg";
import { ReactComponent as IconTwitter } from "assets/icons/ico-twitter.svg";
import { ReactComponent as EmailIcon } from "assets/icons/icon-mail.svg";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";

function ShareButtons({ url, title, emailBody = "" }) {
  return (
    <StyledContainer>
      <div className="network-share-button" onClick={() => {}}>
        {/* title (string): Title of the shared page
                     via: (string)
                    hashtags (array): Hashtags 
                    related (array): Accounts to recommend following */}
        <TwitterShareButton url={url} title={title}>
          <IconTwitter className="share-button" />
        </TwitterShareButton>
      </div>

      <div className="network-share-button" onClick={() => {}}>
        {/* quote (string): A quote to be shared along with the link.
                    hashtag (string): A hashtag specified by the developer to be
                    added to the shared content. People will still have the
                    opportunity to remove this hashtag in the dialog. The
                    hashtag should include the hash symbol. */}
        <FacebookShareButton url={url} quote={title} hashtag={""}>
          <IconFacebook className="share-button" />
        </FacebookShareButton>
      </div>

      <div className="network-share-button" onClick={() => {}}>
        {/* title (string): Title of the shared page summary (string):
                    Description of the shared page source (string): Source of
                    the content (e.g. your website or application name) */}
        <LinkedinShareButton url={url} title={title}>
          <IconLinkedIn className="share-button" />
        </LinkedinShareButton>
      </div>
      <div className="network-share-button" onClick={() => {}}>
        <EmailShareButton url={url} subject={title} body={emailBody}>
          <EmailIcon className="share-button" />
        </EmailShareButton>
      </div>
    </StyledContainer>
  );
}

const StyledContainer = styled.div.attrs({
  className: "share-buttons",
})`
  display: inline-block;

  .network-share-button {
    cursor: pointer;
    display: flex;
    width: 40px;
    height: 40px;
    background-color: #1020370a;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    margin-bottom: 6px;
    > button {
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
    .share-button {
      fill: #1b2224;
    }
    svg {
      height: 18px;
      width: 18px;
    }
  }
`;

export default ShareButtons;
