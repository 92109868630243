import { put } from "redux-saga/effects";
import { DATA_DELETION_STATUS_REQUEST } from "./actions";
import Api from '../../services/api';
import { GOALSWELL_API } from '../../utils/constants';

export function* getDataDeletionStatusAction(action) {
  const confirmation = action.confirmationCode;
  try {
    const config = {
      params: {
        confirmation
      }
    };

    const url = `${GOALSWELL_API}/auth/sso/facebook/delete`;
    const { status, data } = yield Api.get(url, config);
    console.log(`GET ${url} with ${JSON.stringify(config, null, 2)}, results: ${status}, data: ${JSON.stringify(data, null, 2)}`);

    yield put({ type: DATA_DELETION_STATUS_REQUEST, payload: {
      isDataDeleted: data.success && data.deleted,
      confirmationCode: confirmation,
      error: undefined
    }});

  } catch (err) {
    let isDataDeleted = false;
    let error = 500;
    const { response } = err;
    if (response) {
      let { status, data } = response;
      console.log(status);
      console.log(JSON.stringify(data, null, 2));
      isDataDeleted = data && data.success;
      error = status || 500;
    } else {
      console.log(JSON.stringify(err, null, 2));
    }

    yield put({ type: DATA_DELETION_STATUS_REQUEST, payload: {
      isDataDeleted,
      confirmationCode: confirmation,
      error
    }});
  }
}