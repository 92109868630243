import React from "react";
import propTypes from "prop-types";
import styled from "styled-components";
import ArrowButton from "components/ArrowButton2";

function FeaturedSection({ featuredBlog }) {
  return (
    <StyledContainer>
      <div className="center-block">
        <div className="text-part">
          <div className="text-label">Featured Article</div>
          <div className="text-title">{featuredBlog.title}</div>
          <div className="text-author">
            Published by{" "}
            <span className="name">{featuredBlog?.user?.name}</span> •{" "}
            {featuredBlog.duration}
          </div>
          <div className="text-desc">{featuredBlog.description}</div>
          <ArrowButton
            text="Continue Reading"
            route={"/blogs" + featuredBlog.path}
          />
        </div>
        <div className="media-part">
          <img
            src={featuredBlog.coverImageUrl}
            alt={featuredBlog.coverImageAlt}
          />
        </div>
      </div>
    </StyledContainer>
  );
}

const StyledContainer = styled.div.attrs({
  className: "",
})`
  padding: 30px 24px;
  @media screen and (max-width: 768px) {
    padding: 24px 0px;
  }
  .center-block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 48px;
    grid-column-gap: 48px;
    row-gap: 48px;
    grid-row-gap: 48px;

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
      column-gap: 18px;
      grid-column-gap: 18px;
      row-gap: 18px;
      grid-row-gap: 18px;
      grid-template-areas:
        "a"
        "b";
    }
  }

  .text-part {
    @media screen and (max-width: 768px) {
      grid-area: b;
    }
    .text-label {
      color: #e1e3e6;
      font-size: 14px;
      margin-bottom: 10px;
      background-color: #102037;
      padding: 6px 12px;
      width: fit-content;
      opacity: 0.9;
      @media screen and (max-width: 768px) {
        margin-bottom: 16px;
      }
    }

    .text-title {
      font-size: 45px;
      line-height: 78px;
      font-weight: bold;
      margin-bottom: 24px;
      color: #1b2224;

      @media screen and (max-width: 768px) {
        font-size: 25px;
        line-height: 28px;
        font-weight: bold;
        margin-bottom: 24px;
      }
    }

    .text-author {
      margin-bottom: 24px;
      font-size: 16px;
      color: #47525d;
      .name {
        font-weight: bold;
      }
    }

    .text-desc {
      color: #47525d;
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 24px;
      @media screen and (max-width: 768px) {
        font-size: 16px;
        line-height: 24px;
        color: #1b2224;
      }
    }

    .ctaButton {
      /* padding: 8px 14px; */
      cursor: pointer;
      /* border-radius: 6px;
      background-color: #fff; */
    }
  }
  .media-part {
    img {
      /* Requested changes by Jeff */
      /* width: 100%; */
      width: 546px;
      height: 368px;
      object-fit: cover;
      border-radius: 8px;
    }

    @media screen and (max-width: 768px) {
      grid-area: a;
      img {
        width: 100%;
        height: 225px;
      }
    }
  }
`;

FeaturedSection.propTypes = {
  featuredBlog: propTypes.object.isRequired,
};

export default FeaturedSection;
