import React from "react";
import "./LinkButton.scss";

function LinkButton({
  className="",
  text,
  route,
  onClick = () => {},
  target = null,
}) {
  className = `${className} container`;
  return (
    <div className={className}>
      <a
        href={route}
        target={
          target ? target :
          route && (route.startsWith("/") || route.startsWith("mailto:")) ? "_self" :  // Relative URL: open in the same window
          "_blank"  // Others (full URL): open in a new window
        }
        onClick={onClick}
      >
        {text}
      </a>
    </div>
  );
}

export default LinkButton;
