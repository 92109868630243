import "antd/dist/antd.css";
import { message } from "antd";
import { createGlobalStyle } from "styled-components";

message.config({
  top: 24,
  duration: 3,
  maxCount: 10,
  rtl: false,
  getContainer: () => document.getElementById("root"),
});

const GlobalAntdOverwriteStyle = createGlobalStyle`
    @keyframes mask-fade-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    #App {
        .ant-btn {
            border-radius: 5px;
        }

        .ant-input-affix-wrapper {
            border-radius: 5px;
        }

        .ant-input-search-icon::before {
            border-left: none;
        }
    }

    .ant-modal {
		.ant-modal-header {
			border-bottom: none;
			border-radius: 16px 16px 0 0;
			.ant-modal-title {
				font-size: 20px;
				font-weight: bold;
			}
		}

		.ant-modal-content {
			border-radius: 16px;
            overflow: hidden;
            box-shadow: 0px 6px 18px #10203714;
		}

		.ant-modal-footer {
			border-top: none;
			border-radius: 0 0 16px 16px;
		}
	}

    .ant-modal-root{
        .ant-modal-mask{
            background: transparent
                linear-gradient(180deg, #10203799 0%, #10203729 100%) 0% 0%
                no-repeat padding-box;

            &.fade-enter-active{
                animation: mask-fade-in 0.3s cubic-bezier(0.46, 0, 0.3, 1);
            }
        }
    }

    .ant-modal.full-screen-modal{
        width: 100% !important;
        max-width: 100% !important;
        padding-bottom: 0px !important;
        margin: 0;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        .ant-modal-content{
            border-radius: 0;
            min-height: 100vh;

            .ant-modal-close-icon {
                color: var(--ml-gray-6);
                height: 32px;
                width: 32px;
                background-color: #10203714;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 32px;
                position: relative;
                top: 16px;
                right: 113px;
                @media screen and (max-width: 768px) {
                    right: 6px;
                }
                > svg {
                    fill: var(--ml-gray-6);
                    height: 32px;
                    width: 32px;
                }
            }

            .ant-modal-body {
                padding: 0;
            }
        }
    }

    @keyframes antZoomIn2 {
        0% {
            transform: scale(0.85);
            opacity: 0;
        }
        100% {
            transform: scale(1);
            opacity: 1;
        }
    }
    @keyframes antZoomOut2 {
        0% {
            opacity: 1;
            transform: scale(1);
        }
        100% {
            transform: scale(0.85);
            opacity: 0;
        }
    }
    .ant-modal.zoom-enter,
    .ant-modal.zoom-appear {
        animation-duration: 0.3s !important;
    }
    .zoom-enter.zoom-enter-active,
    .zoom-appear.zoom-appear-active {
        animation-name: antZoomIn2 !important;
        animation-play-state: running;
    }
    .zoom-enter,
    .zoom-appear {
        transform: scale(0.85) !important;
        opacity: 0;
        animation-timing-function: cubic-bezier(0.46, 0, 0.3, 1);
    }
    .zoom-leave {
        animation-timing-function: cubic-bezier(0.46, 0, 0.3, 1);
    }
    .zoom-leave.zoom-leave-active {
        animation-duration: 0.3s !important;
        animation-name: antZoomOut2 !important;
        animation-play-state: running;
    }

    .page-section {
        width: 100%;
    }

    .center-block {
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 24px;
    padding-right: 24px;
        @media screen and (max-width: 768px) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
`;

export default GlobalAntdOverwriteStyle;
