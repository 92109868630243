import React from "react";
import "./PolicyLink.scss";

const PolicyLink = function ({ text, policy, showPolicy }) {
  return (
    <a href="/#" target="_self" onClick={(e) => showPolicy(policy)(e)}>
      {text}
    </a>
  );
};

export default PolicyLink;
