import React, { useEffect, useState } from "react";
import * as _ from "lodash-es";
import styled from "styled-components";
import axios from "axios";
import { Spin } from "antd";
import { GOALSWELL_API } from "utils/constants";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import portrait from "assets/images/portrait.jpg";
import ShareButtons from "components/ShareButtons";
import { Header as GlobalHeader, Footer } from "components";
import Header from "./Header";

function processText(text) {
  let result = text
    // combine carriage returns
    .replace(/\r\n\r/g, "\n")

    // <br></br> => <br/>
    .replace(/<br\s*>\s*<\/\s*br>/gi, "<br/>")

    // Combine white spaces
    .replace(/\s+$/g, "")
    .split(/\n/i);

  // console.log(`blocks before map: '${JSON.stringify(result, null, 2)}`);
  result = result.map((block) => {
    // Remove carriage returns
    block = block.replace(/\n|\r/gi, "");

    // If the <img> and <video> are outside of <p> blocks, remove the immediate <br/>
    if (!/^<p>/i.test(block)) {
      block = block
        .replace(/video>\s*<br\s*\/>/gi, "video>")
        .replace(/img>\s*<br\s*\/>/gi, "img>");
    }

    // return <video>, <resource>, <p> and <h?> elements directly
    if (/^<\/*?video|^<\/*?source|<video|^<p.*<\/*?p|^<h[1-6]/gi.test(block)) {
      return block;
    }

    // Wrap other elements inside <span>
    return `<span>${block}</span>`;
  });
  // console.log(`blocks after map: '${JSON.stringify(result, null, 2)}`);

  // Remove empty <p> blocks
  result = result.filter((el) => el !== "<p></p>");
  // console.log(`blocks after filter: '${JSON.stringify(result, null, 2)}`);

  result = result.join("");
  return result;
}

function BlogDetail() {
  let { path } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    const url = `${GOALSWELL_API}/blog?path=/${path}`;
    axios
      .get(url)
      .then((res) => {
        setIsLoading(false);
        const data = res.data.blog;
        setBlog(data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, [path]);

  if (!blog) {
    return null;
  }

  return (
    <Spin tip="Loading" spinning={isLoading}>
      <StyledContainer mediaUrl={blog.coverImageUrl}>
        <GlobalHeader showNav={true} />
        <Header />
        <Helmet>
          {blog.meta && blog.meta.title && <title>{blog.meta.title}</title>}
          {_.map(blog.meta || {}, function (value, key) {
            // https://stackoverflow.com/questions/22350105/whats-the-difference-between-meta-name-and-meta-property
            if (key.indexOf(':') >= 0) {
              let name = key.split(':');
              name = name[name.length - 1];
              return <meta property={key} name={name} content={value} key={key} />;
    
            } else {
              return <meta name={key} content={value} key={key} />;
            }
          })}
        </Helmet>
        <div className="full-hero">
          <div className="category-element">
            {blog.categories && blog.categories.join(",")}
          </div>
        </div>
        <div className="blog-title center-block">
          <h1>{blog.title}</h1>
        </div>
        <div className="blog-container">
          <div className="center-block">
            <div className="share-wrapper">
              <div className="share-title">Share</div>
              <ShareButtons
                title={blog.title}
                url={window.location.href}
                emailBody={`${blog.description}`}
              />
            </div>

            <div className="blog-share-container"></div>
            <div className="blog-body">
              <div className="blog-user">
                <img
                  className="portrait"
                  src={blog.user.avatarUrl || portrait}
                  alt="portrait"
                />
                <div className="blog-user-name">{blog.user.name}</div>
                <div className="post-date">{blog.date}</div>
              </div>
              <div className="blog-content">
                <PostContent
                  dangerouslySetInnerHTML={{
                    __html: processText(blog.content),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </StyledContainer>
    </Spin>
  );
}

const StyledContainer = styled.div.attrs({
  className: "page-section",
})`
  width: 100%;
  box-sizing: border-box;

  .full-hero {
    position: relative;
    z-index: 1;
    height: 360px;
    background-image: url(${({ mediaUrl }) => mediaUrl});
    background-position: center center;
    background-size: cover;
    /* min-height: fit-content; */
    background-color: unset;
    .category-element {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 220px;
      height: 80px;
      background: #102037;
      color: #e1e3e6;
      text-align: right;
      padding-top: 25px;
      padding-right: 20px;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .blog-title {
    background-color: #ffffff;
    padding: 4px 24px;
    color: #47525d;
    position: relative;
    left: 0;
    top: -20px;
    z-index: 10;

    h1 {
      font-size: 45px;
      line-height: 59px;
    }

    @media screen and (max-width: 768px) {
      max-width: 80%;
      h1 {
        font-size: 25px;
        line-height: 28px;
        color: #47525d;
      }
    }
  }

  .blog-container {
    position: relative;
    z-index: 2;
    padding: 96px 24px 78px;

    @media screen and (max-width: 768px) {
      padding-top: 0;
    }

    .center-block {
      position: relative;
      padding-left: 120px;
      @media screen and (max-width: 768px) {
        padding-left: 0;
        padding-right: 0;
      }
      .share-wrapper {
        position: absolute;
        left: 24px;
        top: 80px;
        @media screen and (max-width: 768px) {
          position: unset;
          left: unset;
          top: unset;
          margin-bottom: 32px;
        }

        .share-title {
          color: #838c96;
          font-size: 12px;
          margin-bottom: 10px;
          text-align: center;
          @media screen and (max-width: 768px) {
            text-align: left;
          }
        }

        .share-buttons {
          @media screen and (max-width: 768px) {
            display: flex;
            .network-share-button {
              margin-right: 6px;
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .blog-user {
      position: relative;
      padding-left: 50px;
      height: 40px;
      margin-bottom: 32px;
      .portrait {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        position: absolute;
        left: 0;
        top: 0;
      }

      .blog-user-name {
        color: #47525d;
        font-weight: bold;
        font-size: 14px;
        /* margin-bottom: 4px; */
      }

      .post-date {
        font-size: 14px;
        color: #838c96;
      }
    }
  }
`;

const PostContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: 1.375rem;
  font-size: 1.125rem;
  font-weight: 300;

  * {
    box-sizing: border-box;
    width: 100%;
    max-width: 60rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: block;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.17em;
  }

  h4 {
    font-size: 1em;
  }

  h5 {
    font-size: 0.83em;
  }

  h6 {
    font-size: 0.67em;
  }

  p,
  ul {
    margin-bottom: 1.2rem;
  }

  ul {
    padding-left: 2rem;
    list-style: disc outside;
  }

  a {
    text-decoration: underline;
    color: #4386cb;
  }

  blockquote {
    padding: 0 1rem 1rem 1rem;
    margin-bottom: 1rem;
    font-weight: 400;
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: bold;
  }

  sup {
    vertical-align: super;
    font-size: smaller;
  }

  sub {
    vertical-align: sub;
    font-size: smaller;
  }

  code {
    font-family: monospace;
  }
`;

export default BlogDetail;
