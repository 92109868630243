export const DATA_DELETION_STATUS_REQUEST = "DATA_DELETION/STATUS_REQUEST";
export const DATA_DELETION_STATUS_REQUEST_WATCHER = "DATA_DELETION/STATUS_REQUEST_WATCHER";

export const resetDataDeletionStatusAction = () => {    
  return {
    type: DATA_DELETION_STATUS_REQUEST,
    payload: {
      isDataDeleted: false,
      confirmationCode: undefined,
      error: undefined
    }
  };
};

export const getDataDeletionStatusAction = (confirmationCode) => {
  return {
    type: DATA_DELETION_STATUS_REQUEST_WATCHER,
    confirmationCode
  };
};
