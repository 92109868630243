module.exports = Object.freeze({
  MOBLAB_BASE_URL: "https://moblab.com",

  SEND_EMAIL_API: "https://www.formbackend.com/f/d13c04d78def014e",

  GOALSWELL_BASE_URL: "https://www.goalswell.com",

  // API server
  GOALSWELL_API: "https://admin.goalswell.com/api",       // Production API server
  // GOALSWELL_API: "https://goalswell-qa.moblab.com/api",   // QA API server
  // GOALSWELL_API: "https://goalswell-dev.moblab.com/api",  // Dev API server
  // GOALSWELL_API: "http://localhost:3000/api",             // Local API server

  TWITTER_LINK: "https://twitter.com/goalswell",
  FACEBOOK_LINK: "https://www.facebook.com/goalswell",
  INSTAGRAM_LINK: "https://www.instagram.com/goalswell",
  LINKEDIN_LINK: "https://www.linkedin.com/company/goalswell",

  INTERNSHIP_EMAIL: "internships@goalswell.com",

  APP_STORE_URL: "https://apps.apple.com/us/app/goalswell-friends-finances/id1559994420",
  PLAY_STORE_URL: "https://play.google.com/store/apps/details?id=com.goalswell.app.android",

  // SURVEY_URL: "https://forms.gle/bACcuBqm639g9Xy17",
  SURVEY_URL: "https://docs.google.com/forms/d/e/1FAIpQLSd_CgsvPJsOfuUehMxXjVyt_4JRYOVkPfmhGA-ry8pz6yAU2A/viewform"
});
