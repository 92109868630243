import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducer";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootSaga from "./saga";
const persistConfig = {
  key: 'goalswell',
  storage
}

const middleWare = createSagaMiddleware();

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(
  persistedReducer,  
  compose(applyMiddleware(middleWare))
);

middleWare.run(rootSaga);
export const persistor = persistStore(store);