import React/*, { useCallback, useState }*/ from "react";
// import { Modal } from "antd";
// import ArrowButton from "../../ArrowButton";
// import LinkButton from "../../LinkButton";
// import Member from "./Member";
// import { PORTRAIT } from "../../../assets";
import "./AboutUs.scss";
// import { ReactComponent as CloseIcon } from "../../../assets/icons/ico_close.svg";
// import { INTERNSHIP_EMAIL } from "../../../utils/constants";
// import { logJobApplication, logJobView } from '../../../services/analytics';

// const EmailRoute = `mailto:${INTERNSHIP_EMAIL}`;
  
// const CloseIconWrapper = function () {
//   return (
//     <span className="anticon anticon-close ant-modal-close-icon">
//       <CloseIcon />
//     </span>
//   );
// };

const AboutUs = () => {
  // const [isModalVisible, setIsModalVisible] = useState(false);

  // const showModal = useCallback((e) => {
  //   e.preventDefault();
  //   setIsModalVisible(true);
  //   // Log event to analytics
  //   logJobView();
  // }, []);

  // const closeModal = useCallback(() => {
  //   setIsModalVisible(false);
  // }, []);

  // const applyInternship = useCallback(() => {
  //   logJobApplication();
  // }, []);

  return (
    <div className="about-us-container" id="about_us">
      <div className="story-container">
        <div className="section">
          <div className="section-subtitle">A little <span className="blue-text">more about us</span></div>
          <div className="section-text">Goalswell aims to help you set goals for improvement and turn them into daily habits. Grab some friends to join you on your personal or group journeys to achieving your goals together.</div>
        </div>
      </div>
      {/* <div className="team-container">
        <div className="team-body">
        <div className="team-title">
            Our goal setters
          </div>
          <div className="team-members">
            <Member picture={PORTRAIT} name="FirstName LastName" position="Position info" />
            <Member picture={PORTRAIT} name="FirstName LastName" position="Position info" />
            <Member picture={PORTRAIT} name="FirstName LastName" position="Position info" />
          </div>
          <div className="team-title">
            Our monitors
          </div>
          <div className="team-members">
            <Member picture={PORTRAIT} name="FirstName LastName" position="Position info" />
            <Member picture={PORTRAIT} name="FirstName LastName" position="Position info" />
            <Member picture={PORTRAIT} name="FirstName LastName" position="Position info" />
          </div>
        </div>
      </div> */}

      {/* Hide internship section for now */}
      {/* <div className="job-container">
        <div className="job-banner">
          <div className="job-title">
            Interested in internship opportunities?
          </div>
          <div className="job-link">
            <ArrowButton
              text="Show me"
              route="#"
              target="_self"
              onClick={showModal}
            />
          </div>
        </div>
      </div>

      <Modal
        className="job-description-modal"
        title=""
        visible={isModalVisible}
        onCancel={closeModal}
        footer={null}
        closeIcon={<CloseIconWrapper />}
        width="100%"
      >
        <h2>Canada — Full time</h2>
        <h1>Summer Intern: Undergrad</h1>
        <h4>Goalswell Undergraduate Intern</h4>
        <h3>Summary</h3>
        <p>Goalswell is at the cutting edge of social technology and financial education. Join us on our mission to help high school and college students form healthy financial habits. We are currently seeking undergraduate interns. Interns will gain critical research skills, valuable work experience, and real-world technology product experience, as well as career development and mentoring. There will also be potential leadership and team management opportunities.</p>
        <h3>Requirements</h3>
        <p>Interns will participate in entrepreneurial skills, communication and research training and have the opportunity to lead a project that will directly impact our product. Potential projects include:</p>
        <ul style={{marginTop: "0px"}}>
          <li>User testing research, data synthesis and professional reporting</li>
          <li>Competitive market research in financial technology and education</li>
          <li>Social media marketing content development</li>
          <li>Financial education content research and development</li>
          <li>Build relationships for financial educational advocacy with peers, classmates, and community</li>
        </ul>
        <h3 style={{marginTop: "32px"}}>Preferred qualifications</h3>
        <p>We are seeking candidates that have a shared vision for adopting the best possible educational learning content around financial literacy and empowerment, and the following qualifications:</p>
        <ul style={{marginTop: "0px"}}>
          <li>Currently enrolled full-time in an accredited degree program or a recent graduate of within 6 months from the start of the internship</li>
          <li>Authorized to work in Canada throughout the duration of the internship</li>
          <li>Have a GPA of 3.0 or higher</li>
          <li>Previous work experience, leadership & teamwork capabilities, strong interpersonal communication skills</li>
        </ul>
        <h3>Compensation</h3>
        <p>This internship is a paid position and does not contribute towards school credit.</p>
        <h3 style={{marginTop: "44px"}}>About Us</h3>
        <p style={{marginBottom: "44px"}}>Goalswell aims to help students build healthy financial habits together with friends. Join us on an exciting and challenging journey to make a difference through financial education and behavioral change!<br /><br />Goalswell is a subsidiary of MobLab, an educational technology startup focused on developing interactive behavioral games and their applications, especially in the education domain. Our experimental platform has been in thousands of classrooms around the world, from high school to higher education to business schools, to help students learn social and economic interactions through hands-on and immersive learning.</p>
        <LinkButton
          className="apply-button"
          text="Apply Now"
          route={EmailRoute}
          target="_self"
          onClick={applyInternship}
        />
      </Modal> */}

    </div>
  )
}
export default AboutUs;
