import React from "react";
import { HashLink } from 'react-router-hash-link';
import "./Panel.scss";

const PP = function ({ visible, showPolicy }) {
  console.log(`panel PP visible: ${visible}`);
  return (
    <div className="panel" style={{display: visible ? "block" : "none"}}>
      <h1>Privacy Policy</h1>
      <h4>Effective: July 9, 2021</h4>

      <p>This Privacy Policy explains how Goalswell Inc., its parent company, Moblab Inc., and its subsidiaries and affiliate companies (“<b><i>Goalswell </i></b>,” “<b><i>we </i></b>,” or “<b><i>us </i></b>”) collects, uses, and discloses information about you. This Privacy Policy applies when you use our websites, mobile applications, and other online products and services that link to this Privacy Policy (collectively, our “<b><i>Services </i></b>”), contact our customer service team, engage with us on social media, or otherwise interact with us.</p><br/>
      <p>We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of this policy and, in some cases, we may provide you with additional notice (such as adding a statement to our website or sending you a notification). We encourage you to review this Privacy Policy regularly to stay informed about our information practices and the choices available to you.</p><br/>


      <h2>CONTENTS</h2>
      <ul>
        <li><HashLink to="/#collection" smooth={true}>Collection of Information</HashLink></li>
        <li><HashLink to="/#use" smooth={true}>Use of Information</HashLink></li>
        <li><HashLink to="/#sharing" smooth={true}>Sharing of Information</HashLink></li>
        <li><HashLink to="/#analytics" smooth={true}>Analytics</HashLink></li>
        <li><HashLink to="/#transfer" smooth={true}>Transfer of Information to the United States and Other Countries</HashLink></li>
        <li><HashLink to="/#choices" smooth={true}>Your Choices</HashLink></li>
        <li><HashLink to="/#california" smooth={true}>Your California Privacy Rights</HashLink></li>
        <li><HashLink to="/#europe" smooth={true}>Additional Disclosures for Individuals in Europe</HashLink></li>
        <li><HashLink to="/#contact" smooth={true}>Contact Us</HashLink></li>
      </ul><br/>


      <h2 id="collection">COLLECTION OF INFORMATION</h2>
      <h3>Information You Provide to Us</h3>
      <p>We collect information you provide directly to us, such as when you create an account, use another provider to login to our Services, provide a contact’s information to join the app, fill out a form, submit or post content through our Services, communicate with us via third-party platforms, participate in a contest or promotion, request customer support, or otherwise communicate with us. The types of personal information we may collect include your name, email address, profile picture, information about the goals you set and your progress in meeting them, your connections in the Services, and any other information you choose to provide.</p>

      <h3>Information from Your Contacts List</h3>
      <p>When you set up the Goalswell app, you have the option to upload your contacts in order to invite your friends to connect with you on the app. If you enable this functionality, we will upload the email addresses stored in your address book to the local version of the Goalswell app stored on your device. If you invite any contacts to participate in the app, we will store their email address on our servers. We do not use your contacts email addresses for any purpose other than to invite your friends to participate in the app.</p>

      <h3>Information We Collect Automatically When You Interact with Us</h3>
      <p>When you access or use our Services or otherwise transact business with us, we automatically collect certain information, including:</p>
      <ul>
        <li><b>Activity Information:</b> We collect information about your activity on our Services, such as the time and date you access the Services, your lesson progress, goal activity, social activity, user growth (such as your experience and badges), and which pages you visit.</li>
        <li><b>Device and Usage Information:</b> We collect information about how you access our Services, including data about the device and network you use, such as your hardware model, operating system version, mobile network, IP address, unique device identifiers, browser type, and app version. We also collect information about your activity on our Services, such as access times, pages viewed, links clicked, and the page you visited before navigating to our Services.</li>
        <li><b>Information Collected by Cookies and Similar Tracking Technologies:</b> We (and our service providers) use tracking technologies, such as cookies and web beacons, to collect information about you. Cookies are small data files stored on your hard drive or in device memory that help us improve our Services and your experience, see which areas and features of our Services are popular, and count visits. Web beacons (also known as “pixel tags” or “clear GIFs”) are electronic images that we use on our Services and in our emails to help deliver cookies, count visits, and understand usage and campaign effectiveness. For more information about cookies and how to disable them, see the <HashLink to="/#choices" smooth={true}>Your Choices</HashLink> section below.</li>
      </ul>

      <h3>Information We Collect from Other Sources</h3>
      <p>We obtain information from third-party sources. For example, if your contact invites you to participate in Goalswell, we collect and store your email address. We may collect information about you from data analytics providers. Additionally, if you create or log into your Goalswell account through a third-party platform (such as Apple, Facebook, or Google), we will have access to certain information from that platform, such as your name, email address, profile picture, gender, and language in accordance with the authorization procedures determined by such platform.</p>

      <h3>Information We Derive</h3>
      <p>We may derive information or draw inferences about you based on the information we collect. For example, we may make inferences about your location based on your IP address or infer that you may be interested in certain lessons or goals based on your activity in the Services.</p><br/>


      <h2 id="use">USE OF INFORMATION</h2>
      <p>We use the information we collect to administer your account and to provide, personalize, and improve the Services, <span style={{textDecoration: "underline", color: "red"}}>such as to allow you to set, monitor, and share information about your goals</span>. We also use the information we collect to:</p><br/>

      <ul>
        <li>Send you technical notices, security alerts, and support and administrative messages and to respond to your comments and questions and provide customer <span style={{textDecoration: "underline"}}>service</span>;</li>
        <li>Communicate with you about products and services offered by Goalswell and others and provide news and information that we think will interest you (see the <HashLink to="/#choices" smooth={true}>Your Choices</HashLink> section below for information about how to opt out of these communications at any time);</li>
        <li>Monitor and analyze trends, usage, and activities in connection with our <span style={{textDecoration: "underline"}}>Services</span>;</li>
        <li>Facilitate contests, sweepstakes, and promotions and process and deliver entries and <span style={{textDecoration: "underline"}}>rewards</span>;</li>
        <li>Detect, investigate, and prevent security incidents and other malicious, deceptive, fraudulent, or illegal activity and protect the rights and property of Goalswell and <span style={{textDecoration: "underline"}}>others</span>;</li>
        <li>Debug to identify and repair errors in our <span style={{textDecoration: "underline"}}>Services</span>;</li>
        <li>Comply with our legal and financial obligations; and</li>
        <li>Carry out any other purpose described to you at the time the information was collected.</li>
      </ul><br/>


      <h2 id="sharing">SHARING OF INFORMATION</h2>
      <p>When using Goalswell, you are able to choose how visible to make your goals and achievements to others. You can set the visibility of your goal each time you make a new goal, by selecting the Visibility option and choosing who, if anyone, can see your goal. You can also choose whether you would like another user to monitor your goal(s). You can set the visibility of your achievements in your profile by selecting whether you would like to share your level and/or badges with your followers. You can also share information about your goals outside of the Services, such as to social media services. We will share your information in accordance with your settings, preferences, and choices. We also share personal information in the following circumstances or as otherwise described in this policy: </p>

      <ul>
        <li>We share personal information with vendors, service providers, and consultants that need access to personal information in order to perform services for us, such as companies that assist us with web hosting, sending account-related emails, sending push notifications, fraud prevention, and customer service.</li>
        <li>We may disclose personal information if we believe that disclosure is in accordance with, or required by, any applicable law or legal process, including lawful requests by public authorities to meet national security or law enforcement requirements.</li>
        <li>We may share personal information if we believe that your actions are inconsistent with our user agreements or policies, if we believe that you have violated the law, or if we believe it is necessary to protect the rights, property, and safety of Goalswell, our users, the public, or others.</li>
        <li>We share personal information with our lawyers and other professional advisors where necessary to obtain advice or otherwise protect and manage our business interests.</li>
        <li>We may share personal information in connection with, or during negotiations concerning, any merger, sale of company assets, financing, or acquisition of all or a portion of our business by another company.</li>
        <li>Personal information is shared between and among Goalswell and our current and future parents, affiliates, and subsidiaries and other companies under common control and ownership.</li>
        <li>We share personal information with your consent or at your direction.</li>
      </ul>

      <p>We also share aggregated or de-identified information that cannot reasonably be used to identify you. </p><br/>


      <h2 id="analytics">ANALYTICS</h2>
      <p>We allow others to provide analytics services on our behalf across the web and in mobile apps. These entities may use cookies, web beacons, device identifiers, and other technologies to collect information about your use of our Services and other websites and applications, including your IP address, web browser, mobile network information, pages viewed, time spent on pages or in mobile apps, links clicked, and conversion information. This information may be used by Moblab and others to, among other things, analyze and track data, determine the popularity of certain content, and better understand your online activity.</p><br/>


      <h2 id="transfer">TRANSFER OF INFORMATION TO THE UNITED STATES AND OTHER COUNTRIES</h2>
      <p>Goalswell is headquartered in the United States, and we have operations and service providers in the United States and other countries. Therefore, we and our service providers may transfer your personal information to, or store or access it in, jurisdictions that may not provide levels of data protection that are equivalent to those of your home jurisdiction. We will take steps to ensure that your personal information receives an adequate level of protection in the jurisdictions in which we process it.</p><br/>


      <h2 id="choices">YOUR CHOICES</h2>
      <h3>Account Information</h3>
      <p>You may update and correct certain account information at any time by logging into your account, emailing us at <a href="mailto:legal@goalswell.com" target="_self" rel="noopener noreferrer">legal@goalswell.com</a>. If you wish to delete your account, please click on the “Delete account data” button on your Goalswell Settings page. We will notify you when the deletion has been processed. Once your deletion request has been processed, your account information will be permanently deleted and is not recoverable.</p>

      <h3>Cookies</h3>
      <p>Most web browsers are set to accept cookies by default. If you prefer, you can usually adjust your browser settings to remove or reject browser cookies. Please note that removing or rejecting cookies could affect the availability and functionality of our Services.</p>

      <h3>Communications Preferences</h3>
      <p>You may opt out of receiving promotional emails from Goalswell by following the instructions in those communications. If you opt out, we may still send you non-promotional emails, such as those about your account or our ongoing business relations.</p>

      <h3>Mobile Push Notifications</h3>
      <p>With your consent, we may send push notifications to your mobile device. You can deactivate these messages at any time by changing the notification settings on your mobile device.</p><br/>


      <h2 id="california">YOUR CALIFORNIA PRIVACY RIGHTS</h2>
      <p>The California Consumer Privacy Act or “CCPA” (Cal. Civ. Code § 1798.100 et seq.) affords consumers residing in California certain rights with respect to their personal information. If you are a California resident, this section applies to you.</p>

      <h3><i>California Consumer Privacy Act</i></h3>
      <p>In the preceding 12 months, we have collected the following categories of personal information: identifiers and internet or other electronic network activity. For details about the precise data points we collect and the categories of sources of such collection, please see the <HashLink to="/#collection" smooth={true}>Collection of Information</HashLink> section above. We collect personal information for the business and commercial purposes described in the <HashLink to="/#use" smooth={true}>Use of Information</HashLink> section above. In the preceding 12 months, we have disclosed the following categories of personal information for business purposes to the following categories of recipients:</p><br/>

      <table style={{width: "100%", border: "1px solid black"}}>
        <colgroup>
          <col style={{width: "50%", border: "1px solid black"}}/>
          <col style={{width: "50%", border: "1px solid black"}}/>
        </colgroup>
        <thead>
          <tr>
            <th style={{border: "1px solid black", textAlign: "left", padding: "2px 10px"}}><b><i>Category of Personal Information</i></b></th>
            <th style={{border: "1px solid black", textAlign: "left", padding: "2px 10px"}}><b><i>Categories of Recipients</i></b></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{border: "1px solid black", verticalAlign: "top", padding: "2px 10px"}}>
              <ul>
                <li>Identifiers</li>
                <li>Internet or other electronic network activity</li>
              </ul>
            </td>
            <td style={{border: "1px solid black", verticalAlign: "top", padding: "2px 10px"}}>
              <ul>
                <li>Vendors who provide services to us such as web hosting and cloud storage providers, customer support and customer feedback providers, data analytics providers, fraud prevention providers, software development and information technology providers, mail providers, and advertising providers</li>
                <li>Third parties when compelled by law, such as to law enforcement or other governmental authorities</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table><br/>

      <p>Goalswell does not sell your personal information.</p><br/>
      <p>Subject to certain limitations, you have the right to (1) request to know more about the categories and specific pieces of personal information we collect, use, and disclose, (2) request deletion of your personal information, (3) opt out of any “sales” of your personal information that may be occurring, and (4) not be discriminated against for exercising these rights. You may make these requests by submitting a request at <a href="https://goalswell.zendesk.com/" target="_blank" rel="noopener noreferrer">https://goalswell.zendesk.com/</a> or emailing us at legal@goalswell.com. We will verify your request by asking you to provide information related to your recent interactions with us. We will not discriminate against you if you exercise your rights under the CCPA.</p><br/>
      <p>If we receive your request from an authorized agent, we may ask for evidence that you have provided such agent with a power of attorney or that the agent otherwise has valid written authority to submit requests to exercise rights on your behalf. If you are an authorized agent seeking to make a request, please <HashLink to="/#contact" smooth={true}>contact us</HashLink>.</p><br/>


      <h2 id="europe">ADDITIONAL DISCLOSURES FOR INDIVIDUALS IN EUROPE</h2>
      <p>If you are located in the European Economic Area (EEA), the United Kingdom, or Switzerland, you have certain rights and protections under the law regarding the processing of your personal data, and this section applies to you.</p>

      <h3>Legal Basis for Processing</h3>
      <p>When we process your personal data, we will do so in reliance on the following lawful bases:</p>

      <ul>
        <li>To perform our responsibilities under our contract with you (e.g., processing payments for and providing the products and services you requested).</li>
        <li>When we have a legitimate interest in processing your personal data to operate our business or protect our interests (e.g., to provide, maintain, and improve our products and services, conduct data analytics, and communicate with you).</li>
        <li>To comply with our legal obligations (e.g., to maintain a record of your consents and track those who have opted out of marketing communications).</li>
        <li>When we have your consent to do so (e.g., when you opt in to receive marketing communications from us). When consent is the legal basis for our processing your personal data, you may withdraw such consent at any time.</li>
      </ul>

      <h3>Data Retention</h3>
      <p>Goalswell retains the personal information we receive as described in this Privacy Policy for as long as you use our Services or as necessary to fulfill the purpose(s) for which it was collected, provide our Services, resolve disputes, establish legal defenses, conduct audits, pursue legitimate business purposes, enforce our agreements, and comply with applicable laws.</p>

      <h3>Data Subject Requests</h3>
      <p>You may inquire as to whether Goalswell is processing personal information about you, request access to personal information, and ask that we correct, amend or delete your personal information where it is inaccurate. Where otherwise permitted by applicable law, you may send an e-mail to <a href="mailto:legal@goalswell.com" target="_self" rel="noopener noreferrer">legal@goalswell.com</a> to request access to, receive (port), seek rectification, or request erasure of personal information held about you by Goalswell. Please include your full name, email address associated with your account, and a detailed description of your data request. Such requests will be processed in line with local laws.</p><br/>
      <p>Although Goalswell makes good faith efforts to provide individuals with access to their personal information, there may be circumstances in which Goalswell is unable to provide access, including but not limited to: where the information contains legal privilege, would compromise others’ privacy or other legitimate rights, where the burden or expense of providing access would be disproportionate to the risks to the individual’s privacy in the case in question, or where it is commercially proprietary. If Goalswell determines that access should be restricted in any particular instance, we will provide you with an explanation of why that determination has been made and a contact point for any further inquiries. To protect your privacy, Goalswell will take commercially reasonable steps to verify your identity before granting access to or making any changes to your personal information.</p>

      <h3>Questions or Complaints</h3>
      <p>If you have a concern about our processing of personal data that we are not able to resolve, you have the right to lodge a complaint with the Data Protection Authority where you reside. Contact details for your Data Protection Authority can be found using the links below:</p><br/>

      <p>For individuals in the EEA: <a href="https://edpb.europa.eu/about-edpb/board/members_en" target="_blank" rel="noopener noreferrer">https://edpb.europa.eu/about-edpb/board/members_en</a></p>
      <p>For individuals in the UK: <a href="https://ico.org.uk/global/contact-us/" target="_blank" rel="noopener noreferrer">https://ico.org.uk/global/contact-us/</a></p>
      <p>For individuals in Switzerland: <a href="https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/contact.html" target="_blank" rel="noopener noreferrer">https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/contact.html</a></p><br/>


      <h2 id="contact">CONTACT US</h2>
      <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:legal@goalswell.com" target="_self" rel="noopener noreferrer">legal@goalswell.com</a>.</p>
    </div>
  );
};

export default PP;
