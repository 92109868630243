import React, { useEffect } from "react";
import { SET_GOALS_IMAGE, LANDING_SECTION_BG2 } from "../../assets";
import "./DataDeletionStatus.scss";
import { connect } from 'react-redux';
import { getDataDeletionStatusAction, resetDataDeletionStatusAction } from "../../redux/actions";

const DataDeletionStatus = ({ resetDataDeletionStatusAction, getDataDeletionStatusAction, isDataDeleted, confirmationCode, error }) => {
  useEffect(() => { 
    resetDataDeletionStatusAction();
  }, [resetDataDeletionStatusAction]);

  useEffect(() => { 
    const getStatus = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const confirmation = urlParams.get('confirmation');
      await getDataDeletionStatusAction(confirmation);
    }
    getStatus();
  }, [getDataDeletionStatusAction]);

  return (
    <div className="status-container">
      <img className="section_bg2" src={LANDING_SECTION_BG2} alt="section_bg2" />
      <div className="status-body">
        <div className="status section">                
          <div className="status-section">

            { isDataDeleted ? (<>
              <div className="section-title">Your personal data in Goalswell app has been deleted.</div>
              <div className="section-subtitle">Hope we will see you soon.</div>

            </>) : error === 400 || error === 404 ? (<>
              <div className="section-title">We cannot find your record.</div>
              <div className="section-subtitle">Something is wrong with your request, contact us if you have any questions.</div>

            </>) : (<>
              <div className="section-title">Something happened when we looking for your record.</div>
              <div className="section-subtitle">Try again later, contact us if you have any questions.</div>
            </>)}

            <div className="section-text">Confirmation number: { confirmationCode }</div>

          </div>
          <img className="set_goals_img" src={SET_GOALS_IMAGE} alt="set_goals" />
        </div>     
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    isDataDeleted: state.dataDeletion.isDataDeleted,
    confirmationCode: state.dataDeletion.confirmationCode,
    error: state.dataDeletion.error
  };
};

export default connect(mapStateToProps, {resetDataDeletionStatusAction, getDataDeletionStatusAction}) (DataDeletionStatus);
