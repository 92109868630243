import React from 'react';
import EmailView from '../../EmailView';
import "./GetStarted.scss";
import { FOOTER_SIGNUP_IMAGE} from "../../../assets";
import { connect } from "react-redux";

const GetStarted = ({isEmailRegistered}) => {
  return (
    <div className="getstarted-container">
      <div className="getstarted-body" id="subscribe">
        <img className="footer_signup_img" src={FOOTER_SIGNUP_IMAGE} alt="footer_signup" />
        <div className="content-body">
          <div className="getstarted-header">Be in the know</div>
          <div className="getstarted-desc">{isEmailRegistered
              ? "Thanks! We’ll let you know as soon as Goalswell is released."
              : "Sign up and we’ll let you know when the app is available in your region!"}</div>
          <EmailView/>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    isEmailRegistered: state.landing.isEmailRegistered,
  };
};

export default connect(mapStateToProps, {})(GetStarted);