import React from 'react';
import "./Survey.scss";
import { SURVEY_URL } from "../../utils/constants";

export default function Survey() {
  return (
    <iframe
      className="survey-container"
      src={SURVEY_URL}
      title="Goalswell New User Feedback Survey"
    >
    </iframe>
  )
}