import React from 'react';
import { render } from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// The trick for multi-routing deployment on S3
// https://via.studio/journal/hosting-a-reactjs-app-with-routing-on-aws-s3
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

let app = document.getElementById('root');
if (app) {
    // 1. Set up the browser history with the updated location
    // (minus the # sign)
	const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
	if (path) {
		history.replace(path);
	}

    // 2. Render our app
	render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    app
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
