import React from "react";
import PolicyLink from "./PolicyLink";
import "./Panel.scss";

const TOU = function ({ visible, showPolicy }) {
  // console.log(`panel TOU visible: ${visible}`);
  return (
    <div className="panel" style={{display: visible ? "block" : "none"}}>
      <h1>Terms of Use</h1>
      <h4>Effective: March 19, 2021</h4>

      <p>
        Whenever you use the Goalswell website or the Goalswell services (collectively, “Services”), these terms apply to you. If you do not agree to all of these terms, please do not use the Services. If you visit the website or use our applications or any other services, you are agreeing to these terms. These terms are one part of the agreement between you, on the one hand, and MobLab Inc. and Goalswell Inc. (together,“Goalswell-Moblab,” “we,” or “us”), on the other hand (“Agreement”). If you use any Goalswell applications, the Agreement also includes the Goalswell App <PolicyLink text="End User License Agreement" policy="eula" showPolicy={showPolicy} /> (“EULA”). Your Agreement with Goalswell-MobLab also includes our <PolicyLink text="Privacy Policy" policy="pp" showPolicy={showPolicy} /> and any policies on the Goalswell website. For clarity, when we say “you” or “user” we mean everyone who is using our Services.
      </p><br/>
      <p><b>
        These terms contain a class-action waiver in Section 12 that affects your rights about how to resolve disputes. If you live in the United States, please read it carefully.
      </b></p><br/>
      <p>
        As a precondition for using the Services, you must agree to these terms. If you accept these terms, you represent that you are age 13 or older. If you are between the ages of 13 and 17 or otherwise do not have the authority to enter into agreements such as these terms, you represent that your legal guardian, or a holder of parental responsibility, has reviewed and agreed to these terms.
      </p><br/>
      <p>
        Certain of our Services may be subject to additional terms: additional terms may be shown on the landing page for the relevant Service and will supersede any conflicting terms in these Terms of Use.
      </p>

      <h3>1. User Information</h3>
      <ul>
        <li>You will use your school or personal email address as log-in ID (“Log-in ID”) when you register for any account. You must not share your Log-in ID or password with any other person. Please notify us right away if you have lost control of your Log-in ID or password or you suspect there is unauthorized activity in your account.</li>
        <li>You should be aware of the way we collect and share the information we collect from the goals, lessons and related activities (including, for example, posts by you and your reactions to posts by other users) run on our Services (collectively, “Activity Data”). In particular, you should not reveal any personal information while posting content on the Services because this information becomes part of the Activity Data. Please be aware that if you register for an account, your name and profile photo will be visible to all other users of the Service when they use the user search functionality within the Service. Please read our <PolicyLink text="Privacy Policy" policy="pp" showPolicy={showPolicy} /> for more information about how your information is collected and used.</li>
        <li>Do not submit sensitive information through the Services, including but not limited to:
          <ul>
            <li>Social Security number</li>
            <li>Driver’s license number</li>
            <li>Financial or payment card information</li>
            <li>Personal data revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union membership</li>
            <li>Genetic data</li>
            <li>Biometric data for the purpose of uniquely identifying a natural person</li>
            <li>Data concerning health or data concerning a natural person’s sex life or sexual orientation.</li>
          </ul>
        </li>
      </ul>

      <h3>2. Changes to Services, Fees</h3>
      <ul>
        <li>We are constantly evolving our Services. This means we may change or discontinue all or part of our Services without notice or liability to you.</li>
        <li>We may decide to charge or change the fee or payment terms for any Service from time-to-time. We will notify you in advance if we decide to charge for a Service for which we currently do not charge.</li>
      </ul>

      <h3>3. Things You Cannot Do</h3>
      <ul>
        <li>Lie to us in connection with your use of our Services. This includes but is not limited to giving false information in your account registration or your profile.</li>
        <li>Open multiple accounts.</li>
        <li>Open a new account without our written permission if we have terminated your prior account or suspended your access to the Services.</li>
        <li>Transfer your account to someone else.</li>
        <li>Resell, rent or lease your account or use of the Services.</li>
        <li>Do anything that may be construed as bad online citizenship such as spam our users, distribute spyware or viruses, collect personal information from a user, or attempt to reverse engineer or hack into our systems.</li>
        <li>Post anything that could be considered defamatory, libelous or criminal.</li>
        <li>Infringe any third party rights, including rights of publicity or privacy, and intellectual property rights.</li>
        <li>Bypass any technical protections or throttling that we institute, or access, use or scrape the Services by any automated means unless you are a search engine crawling the website for the sole purpose of creating a publicly accessible search index.</li>
        <li>Register for any account if you are under 13, or under 16 in the European Economic Area, Switzerland or the UK.</li>
      </ul><br/>
      <p>
        We will take any action we decide is appropriate in response to any of the foregoing bad actions, including suspending or terminating your account.
      </p>

      <h3>4. Activity Data</h3>
      <ul>
        <li>Goalswell-MobLab will receive a copy of all Activity Data.</li>
        <li>Goalswell-MobLab may use and share Activity Data in accordance with the <PolicyLink text="Privacy Policy" policy="pp" showPolicy={showPolicy} />.</li>
      </ul>

      <h3>5. Your Content</h3>
      <ul>
        <li>We may offer the ability to post comments and other content through our app and on our website. You are 100% responsible and liable for any content you post, including its accuracy, lawfulness and truthfulness. You must own or have all necessary rights to post your content, and it must comply with any content policies we post to our website or otherwise provide to you.</li>
        <li>We reserve the right to delete any content you post at any time, for any or no reason.</li>
      </ul>

      <h3>6. Licenses You Grant Goalswell</h3>
      <ul>
        <li>Goalswell may post any content a user submits to any comment or chat board (or any other forum) hosted on our Services, including <a href="https://www.goalswell.com" target="_blank" rel="noopener noreferrer">www.goalswell.com</a> or any of its sub-domains, or on any of our social media outposts, including but not limited to, Facebook and Twitter, create derivative works from it, distribute it and use it in other ways. You hereby grant us a perpetual, irrevocable, worldwide, non-exclusive, royalty-free, sub-licensable, transferable right to use the content you submit, for either training or promotional purposes, and you irrevocably waive all claims and assertions of moral rights or attribution associated with your content.</li>
        <li>Though Goalswell may post any content posted by you on our Services, the content will not be released to the general public before being made completely anonymous.</li>
      </ul>

      <h3>7. Infringement</h3>
      <ul>
        <li>Goalswell is strongly committed to respecting intellectual property and other rights. Please visit <a href="https://www.goalswell.com" target="_blank" rel="noopener noreferrer">www.goalswell.com</a> to see our <PolicyLink text="Infringement Policy" policy="ip" showPolicy={showPolicy} /> or report infringement.</li>
      </ul>

      <h3>8. Ownership</h3>
      <ul>
        <li>Goalswell retains all right, title and interest in our website, apps, features and other Services, trademarks, service marks and logos, including all underlying software, technology and processes and any enhancements or modifications.</li>
        <li>Except for the attribution described above, you may not use our trademarks.</li>
        <li>Except for rights expressly granted in the Agreement, nothing in the Agreement grants you any other right, title or license.</li>
        <li>If you provide us any feedback or suggestions (“Feedback”) about our Services, including our apps and features, we own the Feedback and can use it freely without any compensation, accounting or attribution to you.</li>
        <li>The Services may link to, frame or incorporate third party content and services. Goalswell-Moblab does not own or take responsibility for such third party or their content and services.</li>
      </ul>

      <h3>9. Indemnity</h3>
      <ul>
        <li>You will indemnify, defend, and hold harmless Goalswell Inc., MobLab Inc., and its and their respective affiliates, officers, directors, shareholders, employees, contractors, licensors, licensees, agents, and representatives (the “Covered Entities”) against all liability, claims, costs, damages, settlements, and expenses (including interest, penalties, attorney fees and expert witness fees) (“Liabilities”) incurred by any Covered Entity in any way arising out of or relating to this Agreement, your use of the Services and/or your content. We reserve the right, at your expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, and in such case, you agree to cooperate with our defense of such claim.</li>
      </ul>

      <h3>10. Disclaimers and Limits on Liability</h3>
      <ul>
        <li>THE COVERED ENTITIES MAKE NO WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING WITHOUT LIMITATION WARRANTIES OF MERCHANTABILITY, ACCURACY, FITNESS FOR A PARTICULAR USE, AND NON INFRINGEMENT. THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE”. WE DO NOT REPRESENT THAT THE SERVICES WILL BE CONTINUOUSLY AVAILABLE, ERROR FREE, OR FREE OF VIRUSES, OR THAT PROBLEMS WILL BE CORRECTED. Like all Internet businesses, our Services are vulnerable to down time caused by events such as outages in telecommunications, cable and other communications systems, failures of your equipment and access services, scheduled maintenance and events beyond our control such as civil disruption and natural disasters. We make no service level guarantees about our Services.</li>
        <li>THE COVERED ENTITIES ARE NOT RESPONSIBLE FOR THIRD PARTY WEBSITES, SERVICES, SOFTWARE OR ANYTHING ELSE LINKED, FRAMED OR INCORPORATED INTO THE GOALSWELL SERVICES. YOU BEAR ALL RISKS ASSOCIATED WITH USING THIRD PARTY LINKS, WEBSITES, ETC.</li>
        <li>THE COVERED ENTITIES ARE NOT RESPONSIBLE FOR STORING OR BACKING UP ANY ACTIVITY DATA OR YOUR CONTENT. </li>
        <li>TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE COVERED ENTITIES SHALL NOT BE LIABLE FOR DIRECT, CONSEQUENTIAL, INCIDENTAL, INDIRECT, SPECIAL, PUNITIVE OR OTHER DAMAGES (INCLUDING WITHOUT LIMITATION LOST PROFITS, BUSINESS INTERRUPTION OR LOSS OF BUSINESS INFORMATION) ARISING OUT OF OR RELATED TO THE AGREEMENT OR THE USE OF OR INABILITY TO USE THE SERVICES, EVEN IF THEY HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE MAXIMUM CUMULATIVE LIABILITY OF ALL COVERED ENTITIES COLLECTIVELY, FOR ALL ACTIONS ARISING OUT OR RELATED TO THESE TERMS OF SERVICE, THE PRIVACY POLICY AND/OR THE SERVICES IS THE GREATER OF THE AMOUNT YOU HAVE PAID GOALSWELL-MOBLAB FOR THE SERVICES OVER THE PRIOR SIX MONTHS OR $5.00. SOME JURISDICTIONS DO NOT ALLOW LIMITS OF LIABILITY FOR SOME DAMAGES SO THIS LIMITATION MAY NOT APPLY TO YOU.</li>
      </ul>

      <h3>11. Termination</h3>
      <ul>
        <li>We reserve the right to suspend or terminate your access to the Services and/or your account at any time for any or no reason. This is not our exclusive remedy under any circumstances.</li>
        <li>This Agreement will survive termination or suspension of your access to the Services and/or closure of your account.</li>
        <li>We reserve the right to keep copies of all information you provide to the Services, including all goals and all Activity Data, on our systems indefinitely, but we will only use them ourselves as described in the Agreement.</li>
        <li>If you or we close your account, we may retain your personal information, the demographic information you provided and the Activity Data.</li>
        <li>We may offer the options to permanently deactivate your account or put it on “hold” – if so, we will tell you what will happen to your registration data in each case.</li>
      </ul>

      <h3>12. Miscellaneous</h3>
      <ul>
        <li>The Agreement incorporates all Goalswell policies and additional terms found on the website.</li>
        <li>We reserve the right to change these terms at any time. When we make such changes, we will post them to our websites and you will be able to access them through the same link you used before. Your use of the Services after such changes constitutes acceptance of the new terms.</li>
        <li>Goalswell-MobLab will be entitled to recover any legal fees and other costs associated with enforcing the Agreement.</li>
        <li>We will communicate with you either by emailing the address associated with your account or by posting on the website itself. You and we will give any notices required or permitted by the Agreement (other than legal process) by email with the proviso that we will email your account address and you will email us at legal@goalswell.com. Emails to other addresses will not satisfy any notice requirement.</li>
        <li>You are responsible for being informed about and complying with all laws, rules and regulations that apply to your use of the Services.</li>
        <li>The Agreement is the entire agreement between you and Goalswell-MobLab and it replaces any other agreement between us on this subject. Aside from our right to make changes described in this Agreement, any amendments to the Agreement must be in a writing signed by both parties.</li>
        <li>You cannot assign the Agreement without our written agreement. We can assign the agreement to any entity that agrees to be bound by the terms of the Agreement.</li>
        <li>The Agreement is governed by the laws of the State of California, excluding its choice of laws principles. The parties will resolve any disputes in the courts of Los Angeles County, California, except for any optional arbitration as described next. Excluding claims for equitable relief, if the total amount in dispute is less than $10,000, either party may elect to resolve the claim through binding arbitration by initiating arbitration through an established provider that is agreed by the parties. The arbitration must be conducted under the following rules: (a) at the choice of the party seeking relief, the arbitration shall be conducted by telephone, online, or solely on written submissions; (b) no party or witness will make any personal appearance unless the parties agree otherwise; and (c) the winning party may have the arbitrator’s award entered as a judgment in any court of competent jurisdiction.</li>
        <li>Except to the extent applicable law does not permit the following restriction, any claim (in court or in arbitration) must be brought in the initiating party’s individual capacity and not as a plaintiff or class member in any class action or other similar proceeding.</li>
        <li>If any provision of the Agreement is unenforceable, the validity and enforceability of the remaining provisions will not be affected. No waiver will be effective unless it is in an explicit writing and signed by an authorized representative of the waiving party.</li>
      </ul>
    </div>
  );
};

export default TOU;
