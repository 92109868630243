import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import * as routes from "../route/routes";
import {
  Survey,
  DataDeletion,
  Landing,
  BlogCategory,
  BlogDetail,
  BlogAll,
} from "../pages";

export default class Routes extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          {/* https://reacttraining.com/blog/react-router-v5-1/ */}
          <Route exact path={routes.SURVEY} component={Survey} />
          <Route exact path={routes.DATA_DELETION} component={DataDeletion} />
          <Route
            exact
            path="/blogs/category/:category"
            component={BlogCategory}
          />
          <Route exact path="/blogs/all" component={BlogAll} />
          <Route exact path="/blogs/:path" component={BlogDetail} />
          <Route path={routes.PATHS} component={Landing} />
          {/* Currently we don't have 404 page, just show landing page for all other routes */}
          {/* <Route path={routes.LANDING} component={Landing} /> */}
        </Switch>
      </BrowserRouter>
    );
  }
}
