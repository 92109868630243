import React from "react";
import propTypes from "prop-types";
import styled from "styled-components";
import ArrowButton from "components/ArrowButton2";

function TopMediaCards({ title, description, media, link, categories }) {
  return (
    <StyledContainer>
      <div className="image-wrapper">
        <img className="card-image" alt={media.alt} src={media.url} />
        {categories && categories.length ? (
          <div className="categories">{categories.join(",")}</div>
        ) : null}
      </div>

      <div className="card-section">
        <div>
          <div className="card-title">{title}</div>
          <div className="card-desc">{description}</div>
        </div>
        <div className="card-readmore">
          <ArrowButton text={link.text} route={link.route} />
        </div>
      </div>
    </StyledContainer>
  );
}

const StyledContainer = styled.div.attrs({
  className: "card",
})`
  box-shadow: 0px 1px 4px #10203714;
  border: 1px solid #f0f3f5;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .image-wrapper {
    display: block;
    height: 150px;
    width: 100%;
    position: relative;
    .card-image {
      height: 150px;
      width: 100%;
      object-fit: cover;
    }
    .categories {
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 6px 12px;
      background-color: #102037;
      opacity: 0.9;
      color: #e1e3e6;
      text-align: center;
      font-size: 14px;
    }
  }
  .card-section {
    flex-grow: 1;
    padding: 22px 24px 14px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .card-title {
    color: #1b2224;
    font-weight: bold;
    font-size: 16px;
    /* margin-bottom: 4px; */
  }
  .card-desc {
    margin-bottom: 18px;
    color: #47525d;
    font-size: 14px;
  }
  .card-readmore {
  }
`;

TopMediaCards.propTypes = {
  title: propTypes.string.isRequired,
  description: propTypes.string.isRequired,
  link: propTypes.shape({
    text: propTypes.string.isRequired,
    route: propTypes.string.isRequired,
  }),
  media: propTypes.shape({
    url: propTypes.string.isRequired,
    alt: propTypes.string,
  }),
};

export default TopMediaCards;
