import axios from "axios";

const defaultOptions = {
  headers: {
    accept: "application/json",
  },
};

const api = axios.create(defaultOptions);

export default api;
